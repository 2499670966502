import React, { useState } from 'react';
import {
  Button,
  Grid,
  TextField,
  CircularProgress,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  Hidden,
  Alert,
  AlertTitle,
  Typography,
} from '@mui/material';
import { gql } from '@apollo/client';
import { useMutation } from '@apollo/client';

import StandardWrapper from '../components/StandardWrapper';

import { KeyboardArrowLeft } from '@mui/icons-material';
import _ from 'lodash';

const AddSDSLink = ({ user }) => {
  const [name, setName] = useState('');
  const [link, setLink] = useState('');
  const [description, setDescription] = useState('');

  const [errorDialog, setErrorDialog] = useState();
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState();
  const [key, setKey] = useState(false);
  const [validationError, setValidationError] = useState('');

  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToast();
    setErrorDialog(false);
  };

  const resetForm = () => {
    setName('');
    setDescription('');
    setLink('');
    setKey(!key);
  };

  const CREATE_ERROR = gql`
    mutation CreateError($PVI: String, $action: String, $error: String, $data: String) {
      createError(PVI: $PVI, action: $action, error: $error, data: $data)
    }
  `;
  const [createError] = useMutation(CREATE_ERROR);

  const CREATE_SDS_LINK = gql`
    mutation name($link: String!, $name: String!, $description: String) {
      createSDSLink(link: $link, name: $name, description: $description) {
        createdAt
      }
    }
  `;

  const getVariables = () => {
    return {
      name,
      description,
      link,
    };
  };

  const [createSDSLink] = useMutation(CREATE_SDS_LINK, {
    onError(e) {
      if (e.message.includes('already exists')) {
        setErrorDialog('An SDS with this name or link already exists.');
        setLoading(false);
        return;
      }

      setLoading(false);
      setErrorDialog('Failed to add SDS. The BRMS IT office has been alerted of this issue.');
      setToast();
      createError({
        variables: {
          PVI: user.PVI,
          action: 'createSDSLink',
          error: e ? JSON.stringify(e) : undefined,
          data: JSON.stringify({
            user,
            vars: getVariables(),
          }),
        },
      });
    },
    onCompleted(res) {
      if (!errorDialog) {
        setLoading(false);
        setToast('SDS Added!');
        resetForm();
      } else setToast();
    },
  });

  const handleSubmit = () => {
    const variables = getVariables();
    console.log('variables: ', variables);
    createSDSLink({ variables });
  };

  return (
    <StandardWrapper>
      <Typography variant="h1">Add New SDS</Typography>
      <Grid container justifyContent="space-between">
        <Grid item>
          <IconButton
            aria-label="back"
            onClick={() => {
              window.location.href = '/sds';
            }}
          >
            <KeyboardArrowLeft />
          </IconButton>
        </Grid>
      </Grid>
      <Grid container justifyContent="center">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            setLoading(true);
            handleSubmit();
          }}
        >
          <Grid container justifyContent="center">
            <Grid item>
              <TextField
                variant="outlined"
                style={{ width: 300, margin: 10 }}
                required
                key={key}
                label="Name"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </Grid>
          </Grid>

          <Grid container justifyContent="center">
            <Grid item>
              <TextField
                variant="outlined"
                style={{ width: 300, margin: 10 }}
                required
                key={key}
                label="Link"
                value={link}
                onChange={(e) => {
                  setLink(e.target.value);
                }}
              />
            </Grid>
          </Grid>

          <Hidden xsDown>
            <Grid container>
              <Grid item>
                <TextField
                  variant="outlined"
                  key={key}
                  required={false}
                  label="Description"
                  multiline
                  fullWidth
                  minRows={3}
                  maxRows={6}
                  value={description}
                  style={{ margin: 10, width: 300 }}
                  onChange={(e) => setDescription(e.target.value)}
                ></TextField>
              </Grid>
            </Grid>

            <Grid container justifyContent="flex-end">
              <Grid item style={{ marginTop: 16 }}>
                <Button
                  style={{ width: 125, margin: 10 }}
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={loading}
                >
                  Submit
                  {loading && (
                    <CircularProgress
                      disableShrink
                      style={{ position: 'absolute', zIndex: 2, color: 'white' }}
                      size={24}
                    />
                  )}
                </Button>
              </Grid>
            </Grid>
          </Hidden>
          <Hidden smUp>
            <Grid item>
              <TextField
                variant="outlined"
                required={false}
                key={key}
                label="Description"
                multiline
                fullWidth
                minRows={3}
                maxRows={6}
                value={description}
                style={{ margin: 10, width: 300 }}
                onChange={(e) => setDescription(e.target.value)}
              ></TextField>
            </Grid>

            <Grid item>
              <Button
                style={{ margin: 10, width: 300 }}
                variant="contained"
                color="primary"
                type="submit"
                disabled={loading}
              >
                Submit
                {loading && (
                  <CircularProgress
                    disableShrink
                    style={{ position: 'absolute', zIndex: 2, color: 'white' }}
                    size={24}
                  />
                )}
              </Button>
            </Grid>
          </Hidden>
        </form>
      </Grid>
      <Snackbar open={!!toast} autoHideDuration={6000} onClose={handleToastClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
        <Alert onClose={handleToastClose} variant="filled" severity="success">
          <AlertTitle>Success</AlertTitle>
          {toast}
        </Alert>
      </Snackbar>
      <Dialog open={!!errorDialog} onClose={handleToastClose}>
        <DialogTitle>{'Error'}</DialogTitle>
        <DialogContent>
          <DialogContentText>{errorDialog}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToastClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={!!validationError}
        onClose={() => {
          setValidationError();
        }}
      >
        <DialogTitle>{'Invalid Input'}</DialogTitle>
        <DialogContent>
          <DialogContentText>{validationError}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setValidationError();
            }}
            color="primary"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </StandardWrapper>
  );
};

export default AddSDSLink;
