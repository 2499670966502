import { Box, Button, Grid, IconButton, Link, Stack, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { getStatusColor } from './utils';
import TimeRemaining from './TimeRemaining';
import moment from 'moment';

// Card component
const VCTDashCards = ({ vcrs, handleOpenDetailsDialog }) => {
  const shrLink = 'https://policy.wisc.edu/library/UW-4098';
  const ocrlink = '';
  return (
    <Stack spacing={0.5}>
      {vcrs.length <= 0 ? (
        <>
          <Typography variant="label" sx={{ fontSize: '1.5em' }}>
            No results
          </Typography>
          <Typography variant="label">Try adjusting the settings in the Filter and Sort menu</Typography>
        </>
      ) : (
        vcrs?.map((vcr, i) => (
          <Grid container sx={{ backgroundColor: '#DADFE1', borderRadius: '1em', alignItems: 'center' }} key={i}>
            <Grid container item lg={11} xs={12} sx={{ alignItems: 'center' }}>
              {/* Status Cell */}
              <Grid
                item
                lg={3}
                xs={12}
                sx={{
                  ...getStatusColor(vcr.status),
                  paddingBlock: '.5em',

                  borderRadius: '1em',
                }}
              >
                <Typography variant="label">{vcr.status}</Typography>
              </Grid>

              {/* Deadline */}
              <Grid item lg={2} sm={3} xs={12} align="left" padding=".25em .5em">
                {vcr.status === 'Resolved' ? '' : <TimeRemaining tr={vcr.timeRemaining} />}
              </Grid>

              {/* Report Number */}
              <Grid item sm={3} xs={12} align="left" padding=".25em .5em">
                <Typography variant="label">Report #: </Typography>
                {vcr.reportNumber}
              </Grid>

              {/* Time Submitted */}
              <Grid item lg={3} sm={6} xs={12} align="left" padding=".25em .5em">
                <Typography variant="label">Submitted At: </Typography>
                {moment(vcr.createdAt).format('MM-DD-YY h:mma')}
              </Grid>

              {/* Location */}
              <Grid item lg={5} sm={6} xs={12} align="left" padding=".25em .5em">
                <Typography variant="label">Building: </Typography>
                {vcr.buildingName}
              </Grid>
              <Grid item sm={3} xs={6} align="left" padding=".25em .5em">
                <Typography variant="label">Room: </Typography>
                {vcr.roomNumber}
              </Grid>
              <Grid item sm={3} xs={6} align="left" padding=".25em .5em">
                {vcr.rack && (
                  <>
                    <Typography variant="label">Rack: </Typography>
                    {vcr.rack}
                  </>
                )}
              </Grid>

              {/* Protocol */}
              <Grid item lg={5} md={6} xs={12} align="left" padding=".25em .5em">
                <Typography variant="label">Protocol: </Typography>
                {vcr.protocolLabel}
              </Grid>
              {/* Type */}
              <Grid
                item
                sm={6}
                md={3}
                xs={12}
                align="left"
                padding=".25em .5em"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box>
                  <Typography variant="label">Type: </Typography>
                  {vcr.type}
                </Box>
                {vcr.type === 'Singly-Housed Animal' && (
                  <Link href={shrLink} target="_blank">
                    <InfoIcon sx={{ color: '#0479A8' }} />
                  </Link>
                )}
              </Grid>
              {/* Reason */}
              <Grid item sm={6} md={3} xs={12} align="left" padding=".25em .5em">
                {vcr.reason && (
                  <>
                    <Typography variant="label">Reason: </Typography>
                    {vcr.reason}
                  </>
                )}
              </Grid>

              {/* Additional Description */}
              <Grid item xs={12} align="left" padding=".25em .5em">
                {!!vcr.additionalDescription && (
                  <>
                    <Typography variant="label">Description: </Typography>
                    {vcr.additionalDescription}
                  </>
                )}
              </Grid>
            </Grid>

            {/* Open Button */}
            <Grid item lg={1} xs={12} padding=".5em">
              <Button variant="outlined" fullWidth onClick={() => handleOpenDetailsDialog(vcr)}>
                View / Edit
              </Button>
            </Grid>
          </Grid>
        ))
      )}
    </Stack>
  );
};

export default VCTDashCards;
