import { gql } from '@apollo/client';

export const QUERY_RESOURCES = gql`
  query resources {
    resources {
      _id
      resourceName
      resourceType
      building 
      room
    }
  }
`;

export const QUERY_RESERVATIONS = gql`
  query resourceReservations($resources: [ID]) {
    resourceReservations(resources: $resources) {
      _id
      startDate
      endDate
      title
      resource {
        _id
        resourceName
      }
      protocol
      createdBy {
        PVI
      }
      #createdAt
      rRule
      exDate
    }
  }
`;

export const ADD_RESERVATION = gql`
  mutation addResourceReservation($input: ResourceReservationInput!) {
    addResourceReservation(input: $input) {
      _id
    }
  }
`;

export const UPDATE_RESERVATION = gql`
  mutation updateResourceReservation($input: ResourceReservationInput!) {
    updateResourceReservation(input: $input) {
      _id
    }
  }
`;

export const UPDATE_OCCURRENCE = gql`
  mutation updateSingleOccurrence($input: ResourceReservationInput!, $occurrenceDate: String!) {
    updateSingleOccurrence(input: $input, occurrenceDate: $occurrenceDate) {
      _id
    }
  }
`;

export const CANCEL_RESERVATION = gql`
  mutation cancelResourceReservation($_id: ID!, $cancelOccurrenceInSeries: Boolean, $occurrenceDate: String) {
    cancelResourceReservation(_id: $_id, cancelOccurrenceInSeries: $cancelOccurrenceInSeries, occurrenceDate: $occurrenceDate) {
      _id
    }
  }
`;

export const QUERY_USER_PROTOCOLS = gql`
  query userProtocols {
    userProtocols {
      protocolNumber
      label
    }
  }
`;

export const CREATE_ERROR = gql`
  mutation CreateError(
      $PVI: String,
      $action: String,
      $error: String,
      $data: String,
  ){
      createError (
          PVI: $PVI,
          action: $action,
          error: $error,
          data: $data,
      )
  }
  `;
