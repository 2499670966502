import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Grid,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  Alert,
  AlertTitle,
  Typography,
} from '@mui/material';
import { sleep } from '../utils';
import { gql } from '@apollo/client';
import UserSearch from './UserSearch';
import { useQuery } from '@apollo/client';

const UPDATE_GROUP_MUTATION = gql`
  mutation UpdateGroups($PVI: String!, $groups: [String]!) {
    updateGroups(PVI: $PVI, groups: $groups) {
      firstName
      lastName
      groups
    }
  }
`;

const GET_USERS = gql`
  {
    users {
      PVI
      firstName
      lastName
      emailAddress
      groups
    }
  }
`;

const groupList = [
  'user',
  'arrowUser',
  'superuser',
  'husbandry',
  'admin',
  'husbandrySuperuser',
  'brms',
  'brmsSuperuser',
  'vet',
  'rs',
];

const getTitle = (group) => {
  switch (group) {
    case 'user':
      return 'User';
    case 'arrowUser':
      return 'ARROW User';
    case 'superuser':
      return 'Super User';
    case 'husbandry':
      return 'Husbandry';
    case 'admin':
      return 'Website Administrator';
    case 'husbandrySuperuser':
      return 'Husbandry Superuser';
    case 'brms':
      return 'BRMS Employee';
    case 'brmsSuperuser':
      return 'BRMS Superuser';
    case 'vet':
      return 'Vet';
    case 'rs':
      return 'Research Services';
    default:
      return group;
  }
};

const UpdateUserGroupsForm = ({ user }) => {
  const [addGroupUpdate] = useMutation(UPDATE_GROUP_MUTATION, {
    onError(err) {
      setLoading(false);
      console.log(err);
      setErrorDialog(true);
      setToast(false);
    },
    onCompleted() {
      if (!errorDialog) {
        setLoading(false);
        setToast(true);
        setVars({});
        setSelectedUser();
      } else setToast(false);
    },
  });
  const { loadingUsers, error, data, refetch } = useQuery(GET_USERS);

  const [toast, setToast] = useState();
  const [errorDialog, setErrorDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formReset, setFormReset] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const [vars, setVars] = useState({
    groups: selectedUser && Array.isArray(selectedUser.groups) ? [...selectedUser.groups] : [],
  });

  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToast(false);
    setErrorDialog(false);
  };

  const handleInput = (name, item) => {
    setVars((prevState) => {
      let obj = { ...prevState };
      item !== null ? (obj[name] = item) : (obj[name] = '');
      return obj;
    });
  };

  const setUserFromSearch = (searchUser) => {
    setSelectedUser(searchUser);
    if (searchUser !== null) {
      setVars((prevState) => {
        let obj = { ...prevState };
        obj['groups'] = [...searchUser.groups];
        return obj;
      });
    }
  };

  return (
    <>
      {/* User group update form */}
      <form
        id="group-update-form"
        onSubmit={(e) => {
          e.preventDefault();
          setLoading(true);
          sleep(500);
          addGroupUpdate({ variables: vars });
          refetch();
          setSelectedUser(null);
          setFormReset(!formReset);
        }}
        onKeyPress={(event) => {
          if (event.which === 13 /* Enter */) {
            event.preventDefault();
          }
        }}
      >
        <Grid container justifyContent="left" spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h2">Update User Groups </Typography>
          </Grid>

          <Grid item xs={12}>
            <UserSearch
              key={formReset}
              sendInput={handleInput}
              name="PVI"
              val={vars.userSearchTerm || null}
              onSelectUser={setUserFromSearch}
              loading={loadingUsers}
              error={error}
              data={data}
              refetch={refetch}
            />
          </Grid>
          {selectedUser ? (
            <Grid item xs={12} key={formReset}>
              {groupList.map((group, i) => (
                <FormControlLabel
                  className="checkbox"
                  key={i}
                  disabled={group === 'admin' && user.PVI === selectedUser.PVI}
                  control={
                    <Checkbox
                      checked={vars.groups.includes(group)}
                      onChange={(e) => {
                        if (e.target) {
                          setVars((prevState) => {
                            let obj = { ...prevState };
                            if (obj.groups) {
                              if (obj.groups.includes(group)) obj.groups = obj.groups.filter((x) => x !== group);
                              else obj.groups.push(group);
                              return obj;
                            }
                            return prevState;
                          });
                        }
                      }}
                    ></Checkbox>
                  }
                  label={getTitle(group)}
                ></FormControlLabel>
              ))}
            </Grid>
          ) : (
            <Grid item xs={12} />
          )}

          <Grid item xs={12} textAlign="right">
            <Button style={{ width: '10em' }} variant="contained" color="primary" type="submit" disabled={loading}>
              Submit
              {loading && (
                <CircularProgress disableShrink style={{ position: 'absolute', zIndex: 2, color: 'white' }} size={24} />
              )}
            </Button>
          </Grid>
        </Grid>
      </form>

      {/* Success */}
      <Snackbar open={toast} autoHideDuration={6000} onClose={handleToastClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
        <Alert onClose={handleToastClose} variant="filled" severity="success">
          <AlertTitle>Success</AlertTitle>
          Group update has been submitted!
        </Alert>
      </Snackbar>

      {/* Non-specific error */}
      <Dialog open={errorDialog} onClose={handleToastClose}>
        <DialogTitle>{'Error'}</DialogTitle>
        <DialogContent>
          <DialogContentText>The group update failed to submit.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToastClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UpdateUserGroupsForm;
