import React, { useState, useEffect } from 'react';
import { gql } from '@apollo/client';
import {
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Stack,
  FormControl,
  Radio,
  RadioGroup,
  FormLabel,
  Typography,
  Alert,
  AlertTitle,
} from '@mui/material';
import ProtocolSearch from '../components/ProtocolSearch';
import BuildingSearch from '../components/BuildingSearch';
import SpeciesSearch from '../components/SpeciesSearch';
import { useMutation, useQuery } from '@apollo/client';

import PhoneInput from './PhoneInput';
import EmailList from './EmailList';
import FormDivider from '../components/FormDivider';
import TableSwitch from './TableSwitch';
import TransferContainerSection from './TransferContainerSection';
import SubmitterGroup from './SubmitterGroup';
import SelectInput from './SelectInput';
import { isUSDAAnimal } from './TableSwitch';
import ConditionalClearInput from '../components/ConditionalClearInput';
import { dateIsValid, firstDateIsEarlier, sleep, pigMatch, buildingNameToCode } from '../utils';

import { TimePicker, DatePicker } from '@mui/x-date-pickers';
import moment from 'moment-timezone';
import StandardWrapper from '../components/StandardWrapper';
import { GET_TOASTS } from '../global/graphql';

const TRANSFER_MUTATION = gql`
  mutation SubmitTransfer(
    $transportRequired: Boolean!
    $dateRequired: String!
    $returnDate: String
    $sendingProtocolNumber: String!
    $sendingPI: String
    $receivingProtocolNumber: String!
    $receivingPI: String
    $sendingBuilding: String!
    $receivingBuilding: String!
    $sendingRoom: String!
    $receivingRoom: String!
    $sendingContactName: String!
    $sendingContactEmail: String!
    $sendingContactPhone: String!
    $receivingContactName: String!
    $receivingContactEmail: String!
    $receivingContactPhone: String!
    $receivingBillingString: String
    $terminal: Boolean!
    $bsl: Int!
    $biohazardAgent: String
    $immunodeficient: Boolean!
    $addlRequirements: String
    $feed: String!
    $housing: String!
    $strain: String
    $numMales: Int
    $numFemales: Int
    $numCages: Int
    $species: String!
    $containers: String
    $animals: String
    $addlEmails: String
    $submitterEmail: String!
    $submitterPhone: String
    $chemicalHazard: String
    $naive: Boolean
  ) {
    submitTransfer(
      transportRequired: $transportRequired
      dateRequired: $dateRequired
      returnDate: $returnDate
      sendingProtocolNumber: $sendingProtocolNumber
      sendingPI: $sendingPI
      receivingProtocolNumber: $receivingProtocolNumber
      receivingPI: $receivingPI
      sendingBuilding: $sendingBuilding
      receivingBuilding: $receivingBuilding
      sendingRoom: $sendingRoom
      receivingRoom: $receivingRoom
      sendingContactName: $sendingContactName
      sendingContactEmail: $sendingContactEmail
      sendingContactPhone: $sendingContactPhone
      receivingContactName: $receivingContactName
      receivingContactEmail: $receivingContactEmail
      receivingContactPhone: $receivingContactPhone
      receivingBillingString: $receivingBillingString
      terminal: $terminal
      bsl: $bsl
      biohazardAgent: $biohazardAgent
      immunodeficient: $immunodeficient
      addlRequirements: $addlRequirements
      feed: $feed
      housing: $housing
      strain: $strain
      numMales: $numMales
      numFemales: $numFemales
      numCages: $numCages
      species: $species
      containers: $containers
      animals: $animals
      addlEmails: $addlEmails
      submitterEmail: $submitterEmail
      submitterPhone: $submitterPhone
      chemicalHazard: $chemicalHazard
      naive: $naive
    ) {
      success
      message
    }
  }
`;

const InternalTransferForm = ({ user }) => {
  const [toast, setToast] = useState();
  const [errorDialog, setErrorDialog] = useState(false);
  const [formReset, setFormReset] = useState(false);
  const [sendingReset, setSendingReset] = useState(false);
  const [receivingReset, setReceivingReset] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selfTransportDialog, setSelfTransportDialog] = useState(false);
  const [dateCheckDialog, setDateCheckDialog] = useState(false);
  const [dateWeekendDialog, setDateWeekendDialog] = useState(false);
  const [dateOrderDialog, setDateOrderDialog] = useState(false);
  const [userInfo] = useState(user);
  const [namedContacts, setNamedContacts] = useState([
    {
      name: userInfo.firstName + ' ' + userInfo.lastName,
      email: userInfo.emailAddress,
      origin: 'submitter',
    },
  ]);
  const [vars, setVars] = useState({
    immunodeficient: false,
    terminal: false,
    transportRequired: true,
    naive: false,
    submitterEmail: userInfo.emailAddress,
    dateRequired: null,
  });
  const [protocol, setProtocol] = useState();
  const [arrowProtocol, setArrowProtocol] = useState(true);
  const [recProtocol, setRecProtocol] = useState();
  const [recArrowProtocol, setRecArrowProtocol] = useState(true);
  const [sendingSave, setSendingSave] = useState();
  const [receivingSave, setReceivingSave] = useState();
  const [validationDialogMessage, setValidationDialogMessage] = useState();
  const [pigWarning, setPigWarning] = useState(false);
  const [pigWarningAcknowledged, setPigWarningAcknowledged] = useState(false);
  const [USDA, setUSDA] = useState(isUSDAAnimal(vars.species ? vars.species : '').isUSDA);
  const [infoToasts, setInfoToasts] = useState([false]);
  const [isWeekendTransfer, setIsWeekendTransfer] = useState(false);

  // Info toast logic
  const { data: toastData, loading: toastsLoading } = useQuery(GET_TOASTS, {
    variables: { form: 'transfer' },
  });

  useEffect(() => {
    if (!toastsLoading && Array.isArray(toastData?.toasts)) {
      setInfoToasts(toastData.toasts.map((x) => ({ ...x, active: true })));
    }
  }, [toastsLoading, toastData?.toasts]);

  const disableInfoToast = (_id) => {
    setInfoToasts(
      infoToasts.map((x) => {
        if (_id === x._id) {
          return { ...x, active: false };
        }
        return { ...x };
      })
    );
  };

  // End info toast logic

  const handleSelectProtocol = ({ protocol: _protocol, rec = false }) => {
    // console.log('protocol: ', _protocol);
    if (rec) {
      setRecProtocol(_protocol);
      if (_protocol && _protocol.label) {
        handleInput(
          'receivingPI',
          `${_protocol.label.split(':')[1].split(',')[1].trim()} ${_protocol.label.split(':')[1].split(',')[0].trim()}`
        );
      } else {
        handleInput('receivingPI', '');
      }
    } else {
      setProtocol(_protocol);
      if (_protocol && _protocol.label) {
        handleInput(
          'sendingPI',
          `${_protocol.label.split(':')[1].split(',')[1].trim()} ${_protocol.label.split(':')[1].split(',')[0].trim()}`
        );
      } else {
        handleInput('sendingPI', '');
      }
    }
  };

  const resetForm = () => {
    const _vars = {
      submitterEmail: vars.submitterEmail,
      submitterPhone: '',
      transportRequired: true,
      sendingProtocolNumber: '',
      receivingProtocolNumber: '',
      receivingPI: '',
      sendingPI: '',
      sendingBuilding: '',
      receivingBuilding: '',
      sendingRoom: '',
      receivingRoom: '',
      sendingContactName: '',
      sendingContactEmail: '',
      sendingContactPhone: '',
      receivingContactName: '',
      receivingContactEmail: '',
      receivingContactPhone: '',
      receivingBillingString: '',
      terminal: false,
      bsl: '',
      biohazardAgent: '',
      immunodeficient: false,
      addlRequirements: '',
      feed: '',
      housing: '',
      strain: '',
      numMales: null,
      numFemales: null,
      numCages: null,
      species: '',
      containers: '',
      animals: '',
      addlEmails: '',
      naive: false,
    };

    // console.log('vars: ', vars);

    if (!sendingSave) {
      sendingReset ? setSendingReset(false) : setSendingReset(true);
    } else {
      _vars.sendingProtocolNumber = vars.sendingProtocolNumber;
      _vars.sendingBuilding = vars.sendingBuilding;
      _vars.sendingRoom = vars.sendingRoom;
      _vars.sendingContactName = vars.sendingContactName;
      _vars.sendingContactEmail = vars.sendingContactEmail;
      _vars.sendingContactPhone = vars.sendingContactPhone;
      _vars.transportRequired = vars.transportRequired;
      _vars.dateRequired = vars.dateRequired;
      _vars.timeRequired = vars.timeRequired;
      _vars.returnDate = vars.returnDate;
      _vars.returnTime = vars.returnTime;
    }
    if (!receivingSave) {
      receivingReset ? setReceivingReset(false) : setReceivingReset(true);
    } else {
      _vars.receivingProtocolNumber = vars.receivingProtocolNumber;
      _vars.receivingBuilding = vars.receivingBuilding;
      _vars.receivingRoom = vars.receivingRoom;
      _vars.receivingContactName = vars.receivingContactName;
      _vars.receivingContactEmail = vars.receivingContactEmail;
      _vars.receivingContactPhone = vars.receivingContactPhone;
      _vars.receivingBillingString = vars.receivingBillingString;
    }
    formReset ? setFormReset(false) : setFormReset(true);
    // console.log('resetting vars: ');
    // console.log({
    //   immunodeficient: false,
    //   terminal: false,
    //   transportRequired: true,
    //   submitterEmail: userInfo.emailAddress,
    //   ..._vars,
    // });

    setVars({
      immunodeficient: false,
      terminal: false,
      transportRequired: true,
      submitterEmail: userInfo.emailAddress,
      naive: false,
      ..._vars,
    });
    setIsWeekendTransfer(false);
  };

  const CREATE_ERROR = gql`
    mutation CreateError($PVI: String, $action: String, $error: String, $data: String) {
      createError(PVI: $PVI, action: $action, error: $error, data: $data)
    }
  `;
  const [createError] = useMutation(CREATE_ERROR);

  const [addTransfer] = useMutation(TRANSFER_MUTATION, {
    onError(err) {
      setLoading(false);
      console.error(err);
      setErrorDialog(true);
      setToast(false);
      createError({
        variables: {
          PVI: user.PVI,
          action: 'submitTransfer',
          error: err ? JSON.stringify(err) : undefined,
          data: JSON.stringify({
            user,
            vars,
          }),
        },
      });
    },
    onCompleted() {
      setLoading(false);
      if (!errorDialog) {
        setToast(true);
        resetForm();
      } else setToast(false);
    },
  });

  const addNamedContact = (name, email, origin) => {
    let flag = false;

    setNamedContacts((prevState) => {
      for (let i = 0; i < prevState.length; i++) {
        if (prevState[i].origin === origin) {
          prevState[i].name = name;
          prevState[i].email = email;
          flag = true;
          break;
        }
      }

      if (!flag) {
        prevState.push({
          name: name,
          email: email,
          origin: origin,
        });
      }

      return prevState;
    });
  };
  const validateTable = () => {
    if (USDA.isUSDA) {
      if (!vars.animals || !Array.isArray(JSON.parse(vars.animals)) || JSON.parse(vars.animals).length < 1) {
        setValidationDialogMessage('You must add an animal in the table');
        return false;
      }
    }
    if (!vars.dateRequired) {
      setValidationDialogMessage('Please select a different transfer date');
      return false;
    }
    if (!vars.housing) {
      setValidationDialogMessage('Please select housing requirements');
      return false;
    }
    if (!vars.feed) {
      setValidationDialogMessage('Please select a feed type');
      return false;
    }
    if (!vars.bsl) {
      setValidationDialogMessage('Please select a biosafety level');
      return false;
    }

    return true;
  };

  const appendTime = ({ date, time }) => {
    const timeMoment = moment(time.$d);
    const hour = timeMoment.hour();
    const minute = timeMoment.minute();
    return moment(date.$d).hour(hour).minute(minute).second(0).format();
  };

  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setToast(false);
    setErrorDialog(false);
    setSelfTransportDialog(false);
    setDateCheckDialog(false);
    setDateWeekendDialog(false);
    setDateOrderDialog(false);
    setValidationDialogMessage(false);
    setPigWarning(false);
  };

  const handleTransferDate = (e) => {
    console.log("e: ", e)

    if (!e) {
      return;
    }
    let date = e.toDate()
    console.log("date: ", date)

    const isTyped = date > Date.now();
    const isWeekend = date.getDay() === 0 || date.getDay() === 6;
    if (isTyped) {
      if (isWeekend) {
        setDateWeekendDialog(true);
        setIsWeekendTransfer(true);
        handleInput('transportationRequired', false);
      } else {
        setIsWeekendTransfer(false);
      }
      if (!isWeekend && vars.returnDate && firstDateIsEarlier(vars.returnDate, e)) setDateOrderDialog(true);
      else {
        if (!dateIsValid(date, !isWeekend && vars.transportRequired)) {
          setDateCheckDialog(true);
          handleInput('dateRequired', null);
        } else {
          handleInput('dateRequired', e); // dateFormat(e)
        }
      }
    }
  };

  const handleReturnDate = (e) => {
    if (!e) {
      return;
    }
    let date = e.toDate();
    let base = new Date(Date.parse('01/01/2000'));
    const isTyped = date > base;
    const isWeekend = date.getDay() === 0 || date.getDay() === 6;

    if (isTyped) {
      if (isWeekend) setDateWeekendDialog(true);
      else if (vars.dateRequired && firstDateIsEarlier(e, vars.dateRequired)) setDateOrderDialog(true);
      else {
        // Allowing past transfers
        if (!dateIsValid(date, vars.transportRequired)) setDateCheckDialog(true);
        handleInput('returnDate', e);
      }
    }
  };

  const handleInput = (name, item) => {
    setVars((prevState) => {
      let obj = { ...prevState };
      item !== null ? (obj[name] = item) : (obj[name] = '');
      return obj;
    });
  };

  const deleteContainerFields = (vars) => {
    delete vars.numMales;
    delete vars.numFemales;
    delete vars.numCages;

    return vars;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateTable()) {
      return;
    }
    setLoading(true);
    sleep(500);
    // console.log('--> ' + vars.species);
    // console.log(isUSDAAnimal(vars.species));
    // console.log('USDA: ', USDA);
    USDA.isUSDA ? setVars(deleteContainerFields(vars)) : delete vars.animals;

    const variables = { ...vars };
    if (vars.timeRequired) {
      const timeMoment = moment(vars.timeRequired).tz('America/Chicago');
      const hour = timeMoment.hour();
      const minute = timeMoment.minute();
      variables.dateRequired = moment(vars.dateRequired)
        .tz('America/Chicago')
        .hour(hour)
        .minute(minute)
        .second(0)
        .format();
    }
    if (vars.returnTime) {
      const timeMoment = moment(vars.returnTime).tz('America/Chicago');
      const hour = timeMoment.hour();
      const minute = timeMoment.minute();
      variables.returnDate = moment(vars.returnDate).tz('America/Chicago').hour(hour).minute(minute).second(0).format();
    }
    if (buildingNameToCode(variables.receivingBuilding)) {
      variables.receivingBuilding = buildingNameToCode(variables.receivingBuilding);
    }
    if (buildingNameToCode(variables.sendingBuilding)) {
      variables.sendingBuilding = buildingNameToCode(variables.sendingBuilding);
    }
    // console.log('vars: ', vars);
    // console.log('variables.dateRequired: ', variables.dateRequired);
    // console.log('variables.returnDate: ', variables.returnDate);

    addTransfer({ variables });
  };

  return (
    <StandardWrapper>
      <Typography variant="h1">Internal Transfers</Typography>
      <form
        id="internal-transfer-form"
        onSubmit={handleSubmit}
        onKeyPress={(event) => {
          if (event.which === 13 /* Enter */) {
            event.preventDefault();
          }
        }}
      >
        <Grid container alignContent="center" justifyContent="center" spacing={2} columnSpacing={3}>
          <Grid item xs={12}>
            <FormDivider text="Instructions" />
          </Grid>
          <Grid item xs={12}>
            <Stack textAlign="left" spacing={3}>
              <Typography variant="p" fontWeight="bold">
                &bull; If you are requesting BRMS or SVM-ARM transportation assistance, complete this form at lease 1
                week in advance.
              </Typography>
              <Typography variant="p" fontWeight="bold">
                &bull; When <em>not</em> requesting transportation assistance, complete this form at least 3 business
                days from today's date.
              </Typography>

              <Typography variant="p" fontWeight="bold">
                &bull; You will be contacted by email once the transfer has been approved. If applicable, transportation
                assistance will be confirmed <em>after</em> approval is provided.
              </Typography>

              <Typography variant="p" fontWeight="bold">
                &bull; Please mark cages with purple-colored Transport Cards by{' '}
                <em>7:00 am on the day of transport.</em>
              </Typography>

              <Typography variant="p" fontWeight="bold">
                &bull; Include any transfer date/time flexibility, special handling and/or special husbandry requests in
                the Special Requirements box below.
              </Typography>

              <Typography variant="p" fontWeight="bold">
                &bull; Ensure cage cards are updated per{' '}
                <a
                  style={{ fontWeight: 'bold', color: 'black' }}
                  href="https://policy.wisc.edu/library/UW-4104"
                  target="_blank"
                  rel="noreferrer"
                >
                  UW Policy 4104
                </a>
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <FormDivider text="Submitter Information" />
          </Grid>
          <SubmitterGroup
            userInfo={userInfo}
            sendInput={handleInput}
            addContact={addNamedContact}
            passedKey={formReset + 'Submitter Group'}
            val={vars}
          />
          <Grid item xs={12} textAlign="left">
            <FormControlLabel
              className="checkbox"
              control={
                <Checkbox
                  key={formReset}
                  checked={!isWeekendTransfer && vars.transportRequired}
                  disabled={isWeekendTransfer}
                  onChange={(e) => {
                    setSelfTransportDialog(!e.target.checked);
                    handleInput('transportRequired', e.target.checked);
                    if (vars.dateRequired) {
                      let date = new Date(Date.parse(vars.dateRequired));
                    }
                  }}
                />
              }
              label="Transport Assistance Requested (unavailable for weekend or holiday transfers)"
            />
          </Grid>
          <Grid item xs={12}>
            <FormDivider text="Sending Information" />
          </Grid>
          {arrowProtocol ? (
            <>
              <Grid item xs={12} md={6}>
                <ProtocolSearch
                  required
                  key={sendingReset}
                  name="sendingProtocolNumber"
                  onSelectProtocol={(protocol) => handleSelectProtocol({ protocol, rec: false })}
                  val={vars.sendingProtocolNumber}
                  sendInput={handleInput}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  variant="outlined"
                  key={vars.sendingPI + 'sendingPI'}
                  fullWidth
                  label="PI"
                  value={vars.sendingPI}
                  disabled
                />
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12} md={6}>
                <TextField
                  required
                  variant="outlined"
                  fullWidth
                  label="Protocol Number"
                  placeholder="Enter Protocol Number"
                  value={vars.sendingProtocolNumber}
                  onChange={(e) => handleInput('sendingProtocolNumber', e.target.value)}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  required
                  variant="outlined"
                  fullWidth
                  placeholder="Enter PI Name"
                  label="PI"
                  value={vars.sendingPI}
                  onChange={(e) => handleInput('sendingPI', e.target.value)}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12} md={6} textAlign="left">
            <FormControlLabel
              className="checkbox"
              control={
                <Checkbox
                  key={formReset}
                  checked={arrowProtocol}
                  onChange={(e) => {
                    setArrowProtocol(e.target.checked);
                    setProtocol();
                    handleInput('sendingProtocolNumber', '');
                    handleInput('sendingPI', '');
                  }}
                ></Checkbox>
              }
              label="ARROW Protocol"
            ></FormControlLabel>
          </Grid>
          <Grid item xs={12} md={6}>
            <BuildingSearch
              required
              key={sendingReset}
              freeSolo={!arrowProtocol}
              name="sendingBuilding"
              protocol={protocol}
              val={vars.sendingBuilding}
              sendInput={handleInput}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              key={sendingReset}
              variant="outlined"
              fullWidth
              label="Animal Housing Room"
              placeholder="Enter room #"
              value={vars.sendingRoom || ''}
              onChange={(e) => handleInput('sendingRoom', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              key={sendingReset}
              variant="outlined"
              fullWidth
              label="Sending Contact Name"
              placeholder="Enter name"
              value={vars.sendingContactName || ''}
              onChange={(e) => {
                if (vars.sendingContactEmail) addNamedContact(e.target.value, vars.sendingContactEmail, 'sending');
                handleInput('sendingContactName', e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              key={sendingReset}
              variant="outlined"
              fullWidth
              label="Sending Contact Email"
              placeholder="Enter email"
              type="email"
              value={vars.sendingContactEmail || ''}
              onChange={(e) => {
                if (vars.sendingContactName) addNamedContact(vars.sendingContactName, e.target.value, 'sending');
                handleInput('sendingContactEmail', e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <PhoneInput
              isRequired={true}
              name="sendingContactPhone"
              label="Sending Contact Phone"
              key={sendingReset}
              val={vars.sendingContactPhone || ''}
              sendInput={handleInput}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <DatePicker
              label="Requested Transfer Date"
              value={vars.dateRequired || null}
              renderInput={(props) => <TextField required fullWidth {...props} />}
              onAccept={handleTransferDate}
              onChange={handleTransferDate}
              disablePast
            />
          </Grid>
          <Grid item xs={12} md={6}>
            {vars.dateRequired && (
              <TimePicker
                label="Requested Transfer Time"
                value={vars.timeRequired || null}
                onChange={(e) => handleInput('timeRequired', e)}
                renderInput={(props) => <TextField fullWidth {...props} />}
              />
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <DatePicker
              disablePast
              value={vars.returnDate || null}
              label="Requested Return Date"
              renderInput={(props) => <TextField fullWidth {...props} />}
              onChange={handleReturnDate}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            {vars.returnDate && (
              <TimePicker
                label="Requested Return Time"
                value={vars.returnTime || null}
                onChange={(e) => handleInput('returnTime', e)}
                renderInput={(props) => <TextField fullWidth {...props} />}
              />
            )}
          </Grid>
          <Grid item xs={12} textAlign="left">
            <ConditionalClearInput section="sending" effect={setSendingSave} />
          </Grid>
          <Grid item xs={12} textAlign="left">
            <FormDivider text="Receiving Information" />
          </Grid>
          {recArrowProtocol ? (
            <>
              <Grid item xs={12} md={6}>
                <ProtocolSearch
                  required
                  key={receivingReset}
                  name="receivingProtocolNumber"
                  onSelectProtocol={(protocol) => handleSelectProtocol({ protocol, rec: true })}
                  val={vars.receivingProtocolNumber}
                  sendInput={handleInput}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  variant="outlined"
                  key={vars.receivingPI + 'receivingPI'}
                  fullWidth
                  label="PI"
                  value={vars.receivingPI}
                  disabled
                />
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12} md={6}>
                <TextField
                  required
                  variant="outlined"
                  fullWidth
                  label="Protocol Number"
                  placeholder="Enter Protocol Number"
                  value={vars.receivingProtocolNumber}
                  onChange={(e) => handleInput('receivingProtocolNumber', e.target.value)}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  required
                  variant="outlined"
                  fullWidth
                  placeholder="Enter PI Name"
                  label="PI"
                  value={vars.receivingPI}
                  onChange={(e) => handleInput('receivingPI', e.target.value)}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12} md={6} textAlign="left">
            <FormControlLabel
              className="checkbox"
              control={
                <Checkbox
                  key={formReset}
                  checked={recArrowProtocol}
                  onChange={(e) => {
                    setRecArrowProtocol(e.target.checked);
                    setRecProtocol();
                    handleInput('receivingProtocolNumber', '');
                    handleInput('receivingPI', '');
                  }}
                />
              }
              label="ARROW Protocol"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <BuildingSearch
              required
              key={receivingReset}
              freeSolo={!recArrowProtocol}
              name="receivingBuilding"
              protocol={recProtocol}
              val={vars.receivingBuilding}
              sendInput={handleInput}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              key={receivingReset}
              variant="outlined"
              fullWidth
              label="Animal Housing Room"
              placeholder="Enter room #"
              value={vars.receivingRoom || ''}
              onChange={(e) => handleInput('receivingRoom', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              key={receivingReset}
              variant="outlined"
              fullWidth
              label="Receiving Contact Name"
              placeholder="Enter name"
              value={vars.receivingContactName || ''}
              onChange={(e) => {
                if (vars.receivingContactEmail)
                  addNamedContact(e.target.value, vars.receivingContactEmail, 'receiving');
                handleInput('receivingContactName', e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              key={receivingReset}
              variant="outlined"
              fullWidth
              label="Receiving Contact Email"
              placeholder="Enter email"
              type="email"
              value={vars.receivingContactEmail || ''}
              onChange={(e) => {
                if (vars.receivingContactName) addNamedContact(vars.receivingContactName, e.target.value, 'receiving');
                handleInput('receivingContactEmail', e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <PhoneInput
              isRequired={true}
              name="receivingContactPhone"
              label="Receiving Contact Phone"
              key={receivingReset}
              val={vars.receivingContactPhone || ''}
              sendInput={handleInput}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              key={receivingReset}
              variant="outlined"
              fullWidth
              label="Receiving Billing String"
              placeholder="Enter billing string"
              value={vars.receivingBillingString || ''}
              onChange={(e) => handleInput('receivingBillingString', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6} /> {/* Spacer */}
          <Grid item xs={12} textAlign="left">
            <ConditionalClearInput section="receiving" effect={setReceivingSave} />
          </Grid>
          <Grid item xs={12}>
            <FormDivider text="Animal Information" />
          </Grid>
          <Grid item xs={12} md={6} container textAlign="left">
            <Grid item xs={12}>
              <FormControlLabel
                className="checkbox"
                control={
                  <Checkbox
                    key={formReset}
                    checked={vars.terminal}
                    onChange={(e) => handleInput('terminal', e.target.checked)}
                  />
                }
                label="Animals will undergo terminal procedure at receiving protocol/location"
              />
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                className="checkbox"
                control={
                  <Checkbox
                    key={formReset}
                    checked={vars.immunodeficient}
                    onChange={(e) => handleInput('immunodeficient', e.target.checked)}
                  />
                }
                label="Immunodeficient"
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} textAlign="left">
            <FormControl>
              <FormLabel>Have these animals been used in a previous study?</FormLabel>
              <RadioGroup
                onChange={(e) => handleInput('naive', e.target.value === 'Yes' ? false : true)}
                defaultValue={'Yes'}
                name="radio-buttons-group"
              >
                <FormControlLabel value={'Yes'} control={<Radio />} label="Yes" />
                <FormControlLabel value={'No'} control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <SpeciesSearch
              isRequired
              protocol={recProtocol}
              sendInput={(path, value) => {
                if (!pigWarningAcknowledged && pigMatch(value)) {
                  setPigWarning(true);
                } else if (pigWarningAcknowledged && !pigMatch(value)) {
                  setPigWarningAcknowledged(false);
                }

                handleInput(path, value);
              }}
              val={vars.species}
            />
          </Grid>
          <Grid item xs={12} md={6} /> {/* Spacer */}
          <Grid item xs={12} md={6}>
            <SelectInput
              required
              key={formReset}
              label="Housing Requirements"
              val={vars.housing || null}
              onChange={(e) => handleInput('housing', e.target.value)}
              menu={[
                { value: 'aseptic', text: 'Aseptic' },
                { value: 'conventional', text: 'Conventional' },
              ]}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <SelectInput
              required
              key={formReset}
              label="Feed Type"
              val={vars.feed || null}
              onChange={(e) => handleInput('feed', e.target.value)}
              menu={[
                { value: 'maintenance', text: 'Maintenance' },
                { value: 'breeder', text: 'Breeder' },
                { value: 'Special', text: 'Special' },
              ]}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <SelectInput
              menu={[
                { value: 1, text: '1' },
                { value: 2, text: '2' },
                { value: 3, text: '3' },
              ]}
              label="Biosafety Hazard"
              key={formReset}
              val={vars.bsl || null}
              required
              onChange={(e) => handleInput('bsl', parseInt(e.target.value))}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <SelectInput
              menu={[
                { value: 'None', text: 'None' },
                { value: 'Carcinogen', text: 'Carcinogen' },
                { value: 'Reproductive Hazard/Teratogen', text: 'Reproductive Hazard/Teratogen' },
                { value: 'Toxicant/Toxic Agent', text: 'Toxicant/Toxic Agent' },
              ]}
              label="Chemical Hazard"
              key={formReset}
              val={vars.chemicalHazard}
              required
              onChange={(e) => handleInput('chemicalHazard', e.target.value)}
            />
          </Grid>
          {vars.bsl >= 2 ||
            (vars.chemicalHazard && vars.chemicalHazard !== 'None' && (
              <Grid item xs={12} textAlign="left">
                <TextField
                  required
                  key={formReset}
                  variant="outlined"
                  label="Hazardous Agents"
                  placeholder="Describe the hazardous agent(s)"
                  fullWidth
                  multiline
                  minRows={3}
                  maxRows={6}
                  value={vars.biohazardAgent}
                  onChange={(e) => handleInput('biohazardAgent', e.target.value)}
                />
              </Grid>
            ))}
          {/* {!USDA.found && (
            <Grid item xs={12} textAlign="left">
              <FormControl>
                <FormLabel> Are these individually identifiable USDA animals? </FormLabel>
                <RadioGroup
                  row
                  onChange={(e) => {
                    setUSDA({ found: false, isUSDA: e.target.value === 'true' });
                    console.log(USDA);
                  }}
                >
                  <FormControlLabel
                    className="checkbox"
                    control={<Radio defaultChecked checked={USDA.isUSDA} />}
                    label="Yes"
                    value="true"
                  />
                  <FormControlLabel
                    className="checkbox"
                    control={<Radio checked={!USDA.isUSDA} />}
                    label="No"
                    value="false"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          )} */}
          <TableSwitch
            sendInput={handleInput}
            species={vars.species ? vars.species : ''}
            val={vars.animals}
            key={formReset + 'table-switch'}
            allRequired={false}
            USDAStatus={USDA}
            setUSDAStatus={setUSDA}
          >
            <Grid item xs={12}>
              <FormDivider text="Container Information" />
            </Grid>
            <Grid item xs={12}>
              <TransferContainerSection
                sendInput={handleInput}
                val={vars.containers}
                key={formReset + 'transfer-container'}
                strainRequired={false}
              />
            </Grid>
          </TableSwitch>
          <Grid item xs={12} textAlign="left">
            <FormDivider text="Other Contacts" />
          </Grid>
          <Grid item xs={12}>
            <EmailList key={formReset} sendInput={handleInput} existingContacts={namedContacts} />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              key={formReset}
              label="Special Requirements / Other Information"
              placeholder="Enter additional comments/instructions"
              fullWidth
              multiline
              minRows={3}
              maxRows={6}
              value={vars.addlRequirements}
              onChange={(e) => handleInput('addlRequirements', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} textAlign="right">
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={/*loading*/ false}
              sx={{ width: '10em' }}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>

      <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={toast} autoHideDuration={6000} onClose={handleToastClose}>
        <Alert onClose={handleToastClose} variant="filled" severity="success">
          <AlertTitle>Success</AlertTitle>
          Internal transfer has been submitted!
        </Alert>
      </Snackbar>

      <Dialog open={errorDialog} onClose={handleToastClose}>
        <DialogTitle>{'Error'}</DialogTitle>
        <DialogContent>
          <DialogContentText>The internal transfer failed to submit.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToastClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={dateCheckDialog} onClose={handleToastClose}>
        <DialogTitle>{'Information'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            The requested transfer date should be at least 3 business days from today.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToastClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={dateWeekendDialog} onClose={handleToastClose}>
        <DialogTitle>{'Error'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please note that transportation assistance is not available on weekends.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToastClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={dateOrderDialog} onClose={handleToastClose}>
        <DialogTitle>{'Error'}</DialogTitle>
        <DialogContent>
          <DialogContentText>The return date must be after the transfer date.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToastClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={selfTransportDialog} onClose={handleToastClose}>
        <DialogTitle>{'Notification'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Any self-transportation of animals must be in compliance with transportation policy as outlined
            <a
              href="https://www.rarc.wisc.edu/iacuc/acapac/2011-043-v_campus_transportation_of_laboratory_animals.html"
              style={{ textDecoration: 'none' }}
              target="_blank"
              rel="noreferrer"
            >
              {' '}
              here
            </a>
            .
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToastClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={validationDialogMessage} onClose={handleToastClose}>
        <DialogTitle>{'Form not Completed'}</DialogTitle>
        <DialogContent>
          <DialogContentText>{validationDialogMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToastClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={pigWarning} onClose={handleToastClose}>
        <DialogTitle>{'Warning'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            A disposal fee of $150 per animal will be charged if BRMS is responsible for carcass disposal of an animal
            coming from outside the SMPH.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setPigWarningAcknowledged(true);
              handleToastClose();
            }}
            color="primary"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
      {infoToasts.map((toast, i) => (
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={toast.active}
          onClose={() => disableInfoToast(toast._id)}
          key={i}
        >
          <Alert severity="info">{toast.message}</Alert>
        </Snackbar>
      ))}
    </StandardWrapper>
  );
};

export default InternalTransferForm;
