import React, { useState, useEffect } from 'react';
import {
  TextField,
  List,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  DialogActions,
  Tooltip,
  Avatar,
  Chip,
  Grid,
  Typography,
  Box,
} from '@mui/material';
import { sleep } from '../utils';

const validateEmail = (mail) => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail);

// const setFocus = () => {
//   let emailEntry = document.getElementById('email-entry');
//   emailEntry.focus();
// };

const EmailList = ({ sendInput, existingContacts, instructions }) => {
  const [data, setData] = useState([]);
  const [newRow, setNewRow] = useState({
    id: '1',
  });
  const [clearKey, setClearKey] = useState(false);
  const [errorDialog, setErrorDialog] = useState(false);
  const [duplicateDialog, setDuplicateDialog] = useState(false);
  const [removalDialog, setRemovalDialog] = useState(false);
  const [contacts, setContacts] = useState([]);

  useEffect(() => {
    setContacts([...contacts, existingContacts]);
  }, [existingContacts]);

  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorDialog(false);
    setDuplicateDialog(false);
    setRemovalDialog(false);
  };

  const appendNewRow = (key, e) => {
    let temp = newRow;
    temp[key] = e.target.value;
    setNewRow(temp);
  };

  return (
    <Grid container justifyContent="center" spacing={2} columnSpacing={3} alignItems="center">
      <Grid item xs={12} justifyContent="center">
        <Box display="flex" justifyContent="center">
          <Typography className="instruction-list" style={{ width: 'min(70ch, 100%)' }}>
            {instructions ||
              'In addition to the contacts listed below, a copy of the approval will be emailed to applicable facility and transportation staff. You may add email addresses of additional contacts from sending or receiving groups'}
          </Typography>
        </Box>
      </Grid>

      <Grid item xs={12} display="flex" gap="1em" justifyContent="center">
        <TextField
          size="small"
          placeholder="Enter email"
          label="Additional Contact"
          type="email"
          onChange={(e) => appendNewRow('email', e)}
        />

        <Button
          aria-label="add"
          variant="contained"
          color="primary"
          onClick={() => {
            if (validateEmail(newRow.email)) {
              if (
                existingContacts.filter((c) => c.email === newRow.email).length === 0 &&
                data.filter((c) => c.email === newRow.email).length === 0
              ) {
                let temp = [...data, newRow];
                setData(temp);
                setNewRow({ id: `${newRow.id + 1}` });
                setClearKey(!clearKey);
                sendInput('addlEmails', JSON.stringify(temp));
                // sleep(200).then(setFocus);
              } else setDuplicateDialog(true);
            } else setErrorDialog(true);
          }}
        >
          Add Email
        </Button>
      </Grid>

      <Grid item xs={12}>
        <List className="email-list">
          {existingContacts &&
            existingContacts
              .filter((c) => c.name && validateEmail(c.email))
              .map((contact, index) => (
                <Tooltip title={contact.email} key={index}>
                  <Chip
                    color="secondary"
                    avatar={<Avatar>{contact.name.charAt(0).toUpperCase()}</Avatar>}
                    label={contact.name}
                    style={{
                      margin: 5,
                    }}
                  ></Chip>
                </Tooltip>
              ))}
          {data.map((row, index) => {
            if (existingContacts.filter((c) => c.email === row.email).length > 0) {
              let temp = data.filter((entry) => entry.id !== row.id);
              setData(temp);
              sendInput('addlEmails', JSON.stringify(temp));
              setRemovalDialog(true);
              return <></>;
            }
            return (
              <Chip
                color="secondary"
                onDelete={({ id = row.id }) => {
                  let temp = data.filter((entry) => entry.id !== id);
                  setData(temp);
                  sendInput('addlEmails', JSON.stringify(temp));
                }}
                avatar={<Avatar>{row.email.charAt(0).toUpperCase()}</Avatar>}
                label={row.email}
                style={{
                  margin: 5,
                }}
                key={index}
              ></Chip>
            );
          })}
        </List>
      </Grid>
      <Dialog open={errorDialog} onClose={handleToastClose}>
        <DialogTitle>{'Error'}</DialogTitle>
        <DialogContent>
          <DialogContentText>Ensure that the additional emails are valid.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToastClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={duplicateDialog} onClose={handleToastClose}>
        <DialogTitle>{'Error'}</DialogTitle>
        <DialogContent>
          <DialogContentText>This email has already been entered.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToastClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={removalDialog} onClose={handleToastClose}>
        <DialogTitle>{'Notification'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This email has already been entered as an additional email. The additional email has been removed.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToastClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default EmailList;