import { gql } from '@apollo/client';

import { CREATE_ERROR } from '../../global/graphql';

export { CREATE_ERROR };

export const GET_VCRS = gql`
  query vcrs($userId: ID!, $statuses: [String], $createdAt: [Int]) {
    vcrs(userId: $userId, statuses: $statuses, createdAt: $createdAt) {
      _id
      createdAt
      protocolLabel
      type
      reason
      # rack
      # additionalDescription
      # submittedBy {
      #   _id
      #   nameLastFirst
      # }
      protocol {
        members
      }
      staffResolutionRequestedAt
      staffResolutionRequester {
        _id
        nameLastFirst
      }
      resolvedAt
      # resolvedBy {
      #   _id
      #   nameLastFirst
      # }
      # history {
      #   createdAt
      #   submittedBy {
      #     nameLastFirst
      #   }
      #   text
      # }
      location
      # reportNumber
      charged
      deadline
      status
      statusPriority
      timeRemaining
      # roomNumber
      buildingName
    }
  }
`;
// export const GET_FACILITIES = gql`
//   query facilities {
//     facilities {
//       _id
//       name
//       isBRMS
//       rooms {
//         _id
//         number
//       }
//     }
//   }
// `;

// export const GET_ROOMS = gql`
//   query rooms {
//     rooms {
//       _id
//       number
//       building {
//         _id
//         name
//       }
//     }
//   }
// `;

// export const GET_PROTOCOL_OPTIONS = gql`
//   query protocolOptions($fragment: String) {
//     protocolOptions(fragment: $fragment) {
//       _id
//       label
//     }
//   }
// `;
