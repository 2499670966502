import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { LinearProgress } from '@mui/material';
// import { ApolloClient } from 'apollo-client';
import { setContext } from 'apollo-link-context';
// import { InMemoryCache } from 'apollo-cache-inmemory'
import { gql, ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { Redirect } from 'react-router-dom';
// import { createUploadLink } from 'apollo-upload-client'
import StandardWrapper from '../components/StandardWrapper';
import { getServerUrl } from '../utils';

const httpLink = createHttpLink({
  uri: getServerUrl(),
});

const getAuthLink = (token) =>
  setContext((_, { headers }) => {
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

const getClient = (token) => {
  let authLink = getAuthLink(token);
  return new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache({
      dataIdFromObject: (o) => JSON.stringify(o),
    }),
  });
};

const GET_USER_FROM_TOKEN = gql`
  {
    userFromToken {
      _id
      groups
      firstName
      lastName
      emailAddress
      displayName
      PVI
      buildings
    }
  }
`;

const checkGroups = (user) => {
  if (!user || !Array.isArray(user.groups)) {
    return false;
  }
  for (let i = 0; i < user.groups.length; i++) {
    if (['user', 'arrowUser', 'superUser', 'admin', 'vet', 'apiKey'].includes(user.groups[i])) {
      return true;
    }
  }
  return false;
};

const verifyAuth = async ({
  client,
  setAuthorized,
  setAuthenticated,
  setUser,
  setTokenChecked,
  onError = (error) => {
    throw error;
  },
}) => {
  client
    .query({
      query: GET_USER_FROM_TOKEN,
    })
    .then(({ data }) => {
      const { userFromToken: user } = data;
      if (user.sessionExpiration) {
        localStorage.setItem('sessionExpiration', user.sessionExpiration);
      }
      setUser(user);
      setAuthenticated(true);
      setAuthorized(checkGroups(user));
      setTokenChecked(true);
    })
    .catch((error) => {
      try {
        if (
          !(
            error.graphQLErrors[0].message === 'Not authenticated' ||
            error.graphQLErrors[0].message === 'Not authorized'
          )
        )
          onError(error);
      } catch (error2) {
        onError(error);
      }
      localStorage.removeItem('sessionExpiration');
      setAuthenticated(error.graphQLErrors[0].message === 'Not authorized');
      setAuthorized(false);
      setTokenChecked(true);
    });
};

const Auth = ({ onAuth, pathname }) => {
  const [authenticated, setAuthenticated] = useState(false);
  const [authorized, setAuthorized] = useState(false);
  const [user, setUser] = useState();
  const [token, setToken] = useState();
  const [missingToken, setMissingToken] = useState(false);
  const [tokenChecked, setTokenChecked] = useState(false);
  const [client, setClient] = useState();
  const [initialized, setInitialized] = useState(false);

  // Check for token
  let { token: paramToken } = useParams();
  useEffect(() => {
    if (paramToken) {
      setToken(paramToken);
      localStorage.setItem('rmaToken', paramToken);
    } else {
      const storedToken = localStorage.getItem('rmaToken');
      if (storedToken) {
        setToken(storedToken);
      } else {
        setMissingToken(true);
      }
    }
  }, []);

  // If token is present, verify the token
  useEffect(() => {
    if (token) {
      const client = getClient(token);
      setClient(client);
      verifyAuth({ client, setAuthenticated, setAuthorized, setUser, setTokenChecked });
    }
  }, [token]);

  // Once token verification is complete, set the auth status in the app
  useEffect(() => {
    if (tokenChecked && client) {
      onAuth({ user, authenticated, authorized, client });
      setInitialized(true);
    }
  }, [user, authenticated, authorized, client, tokenChecked, onAuth]);

  if (missingToken) {
    return <Redirect to={{ pathname: '/login' }} />;
  }
  // If they showed up with an invalid token. Redirecting to Login (which will redirect them to where they likely got this token) could create a loop
  // Redirect to a page that informs them they are not logged in and allows them to manyally restart the login process (login button to redirect to IDP)
  if (initialized && !authenticated) return <Redirect to={{ pathname: '/authentication-required' }} />;
  // redirect to previous route or dashboard. PrivateRoute will handle the Auth values. If not authenticated it will redirect to /login. If not authorized it will inform the user
  return initialized ? (
    <StandardWrapper>
      <Redirect to={{ pathname: pathname || localStorage.getItem('pathname') || '/' }} />
    </StandardWrapper>
  ) : (
    <StandardWrapper>
      <LinearProgress />
    </StandardWrapper>
  );
};

export default Auth;
