import moment from 'moment-timezone';

const businessDayCount = (startDate, endDate) => {
  let flag = true;
  let day,
    dayCount = 0;
  let d1 = startDate;
  let d2 = endDate;

  while (flag) {
    day = d1.getDay();
    if (day !== 0 && day !== 6) dayCount++;
    d1.setDate(d1.getDate() + 1);
    if (d1.getDate() === d2.getDate() && d1.getMonth() === d2.getMonth() && d1.getYear() === d2.getYear()) flag = false;
  }

  return dayCount;
};

/* EXPORTS */

export const getName = (_user) => {
  if (!_user) return null;
  const { firstName, lastName } = _user;
  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  }
  if (firstName) return firstName;
  if (lastName) return lastName;
  return null;
};

export const isAuthError = (error) => {
  try {
    if (error.graphQLErrors[0].message === 'Not authenticated' || error.graphQLErrors[0].message === 'Not authorized')
      return true;
  } catch (error2) {
    return false;
  }
  return false;
};

export const sleep = (milliseconds) => new Promise((resolve) => setTimeout(resolve, milliseconds));

export const getMinDate = () => {
  let now = new Date().toLocaleString('en-us', { timeZone: 'America/Chicago' });
  let dt = new Date(Date.parse(now));

  return dt;
};

// Set to midnight in Madison to indicate no time preference
export const dateFormat = (date) => moment(date).tz('America/Chicago').hour(0).minute(0).second(0).format();

export const dateIsValid = (requestedDate, transportAssistance) => {
  let today = new Date(Date.now());
  let count = businessDayCount(today, requestedDate);
  console.log('count: ', count);
  return count >= 3;
};

export const firstDateIsEarlier = (date1, date2) => {
  let d1 = new Date(date1);
  let d2 = new Date(date2);
  return +d1 < +d2;
};

export const pigMatch = (string) => {
  if (!string) return false;
  if (typeof string !== 'string') return false;
  if (string.length < 1) return false;

  // // This policy goes into effect at the beginning of 2021, central time
  // if (moment().tz("America/Chicago").year(2021).month(0).date(1).hour(0).minute(0).second(0).unix() > moment().unix()) {
  //   return false
  // }

  const containsPig = string.toLowerCase().includes('pig');
  const containsGuinea = string.toLowerCase().includes('guinea');
  const containsSwine = string.toLowerCase().includes('swine');

  if (containsGuinea) return false;
  return containsPig || containsSwine;
};

export const buildingCodeToName = (x) => {
  if (!x || typeof x !== 'string' || x.length !== 4) {
    return null;
  }
  for (let i = 0; i < ALL_BUILDINGS.length; i++) {
    let { code, name } = ALL_BUILDINGS[i];
    if (x === code) {
      return name;
    }
  }
  return null;
};

export const buildingNameToCode = (x) => {
  if (!x || typeof x !== 'string') {
    return null;
  }
  for (let i = 0; i < ALL_BUILDINGS.length; i++) {
    let { code, name } = ALL_BUILDINGS[i];
    if (x.toLowerCase() === name.toLowerCase()) {
      return code;
    }
  }
  return null;
};

export const capitalize = (s) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const getServerUrl = () => {
  if (process.env.NODE_ENV === 'production') {
    if (process.env.REACT_APP_SERVER_URL) {
      return process.env.REACT_APP_SERVER_URL;
    }
    return 'https://ahw.wisc.edu/api';
  }
  return 'http://localhost:4000';
};

export const getLoginUrl = () => {
  if (process.env.NODE_ENV === 'production') {
    if (process.env.REACT_APP_LOGIN_URL) {
      return process.env.REACT_APP_LOGIN_URL;
    }
    return 'https://ahw.wisc.edu/login';
  }
  // This login token is created with a server secret unique to a developer's local environment that is seperate from other environments
  // To simulate login in a dev environment create a server secret for the GQL server and generate a token with it. Replace the token here
  // While obtainable from the source code, this token will not grant access to any websites as it does not use the production or testing secrets
  return 'http://localhost:3000/auth/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJQVkkiOiJVVzU5NFM5MzUiLCJpYXQiOjE3MjkxOTIwNTcsImV4cCI6MTczMTg3MDQ1N30.qr-tlXic2nUvKCXAHCwJByQKgvEGCapAp40ijhIKCzg';
};

export const inventoryItemsMatch = (x, y) => {
  if (!x || !y) {
    throw new Error('inventoryItemsMatch must be passed two arguments');
  }
  // If they both have an _id return whether or not they match
  if (x._id && y._id) {
    return x._id === y._id;
  }
  // If only one has an _id, return false
  if (x._id || y._id) {
    return false;
  }
  return (
    x.name === y.name &&
    x.unit === y.unit &&
    x.vendor === y.vendor &&
    x.code === y.code &&
    x.description === y.description
  );
};

export const ALL_BUILDINGS = [
  { name: 'Ansci-Animal Sciences', code: '0118' },
  { name: 'Arlington ARS Swine SRTC', code: '0883' },
  { name: 'Biochemical Sciences', code: '0204' },
  { name: 'Biochemistry Addition (BABS)', code: '0205' },
  { name: 'Biotech', code: '0082' },
  { name: 'Biotron', code: '0045' },
  { name: 'Birge', code: '0054' },
  { name: 'Bock Lab', code: '0033' },
  { name: 'Brogden', code: '0470' },
  { name: 'Charmany Instructional', code: '0011' },
  { name: 'CSC-Clinical Science Center', code: '1400' },
  { name: 'Engineering Centers Building', code: '0481' },
  { name: 'Enzyme Institute', code: '0479' },
  { name: 'Harlow', code: '0527' },
  { name: 'Integrative Biology Research', code: '0401' },
  { name: 'IRI-Influenza Research Institute', code: '8128' },
  { name: 'Livestock Lab', code: '0115' },
  { name: 'McArdle', code: '0468' },
  { name: 'Microbial', code: '0060' },
  { name: 'MSC-Medical Sciences Center', code: '0450' },
  { name: 'NutSci-Nutritional Sciences', code: '0449' },
  { name: 'PRL-Poultry Lab', code: '0110' },
  { name: 'Rennebohm', code: '0034' },
  { name: 'Robert P Hanson Biomedical Sciences Laboratories', code: '0094' },
  { name: 'Russell Lab', code: '0114' },
  { name: 'SMI Bardeen', code: '0451' },
  { name: 'SVM-Veterinary Medicine', code: '0093' },
  { name: 'Waisman Center', code: '0459' },
  { name: 'Water Science and Engineering Laboratory', code: '0403' },
  { name: 'WIMR', code: '1485' },
  { name: 'Wisconsin Institute of Discovery', code: '0213' },
  { name: 'WisPIC-Wisconsin Psychiatric Institute And Clinic', code: '0456' },
  { name: 'WisPIC-Wisconsin Psychiatric Institute And Clinics', code: '0456' },
];
