import React from 'react';

import { TextField, Autocomplete, CircularProgress } from '@mui/material';
import { gql } from '@apollo/client';
import { useQuery } from '@apollo/client';
import _ from 'lodash';

const GET_NOTIFICATION_CONDITIONS = gql`
  query NotificationConditions($terms: String, $formatActionToEvent: Boolean) {
    notificationConditions(terms: $terms, formatActionToEvent: $formatActionToEvent) {
      building {
        name
        fpmCode
      }
    }
  }
`;

const InventoryBuildingSearch = ({ key, name, sendInput, val, required = true, label, freeSolo = false }) => {
  const handleSelect = (value) => sendInput(name, value);
  const { loading, error, data, refetch } = useQuery(GET_NOTIFICATION_CONDITIONS, {
    variables: {
      formatActionToEvent: true,
      terms: JSON.stringify({
        autoGenerated: false,
        action: 'orderInventory',
        building: { $exists: true },
        protocol: { $exists: false },
        value: true,
      }),
    },
  });

  if (loading) {
    return <CircularProgress />;
  }
  const buildings =
    data && Array.isArray(data.notificationConditions) ? data.notificationConditions.map((x) => x.building) : [];
  return (
    <Autocomplete
      key={key}
      freeSolo={freeSolo}
      options={loading ? [] : _.uniq(buildings.map((x) => x.name)).sort()}
      value={val}
      noOptionsText={val ? 'No matches' : 'Type to search'}
      onChange={(e, value) => handleSelect(value)}
      renderInput={(params) => (
        <TextField
          {...params}
          required={required}
          fullWidth
          placeholder="Enter building"
          label={label || 'Building'}
          variant="outlined"
          value={val}
          onChange={freeSolo ? (e, value) => handleSelect(e.target.value) : console.log}
        />
      )}
    />
  );
};

export default InventoryBuildingSearch;
