export const USDAList = {
  'laboratory mouse': {
    USDA: '',
    USDAException: 'x',
  },
  'domestic mouse': {
    USDA: '',
    USDAException: 'x',
  },
  'laboratory rat': {
    USDA: '',
    USDAException: 'x',
  },
  'mongolian gerbil': {
    USDA: '',
    USDAException: 'x',
  },
  'domestic syrian hamster': {
    USDA: '',
    USDAException: 'x',
  },
  'domestic guinea pig': {
    USDA: 'x',
    USDAException: '',
  },
  'african pygmy hedgehog': {
    USDA: 'x',
    USDAException: '',
  },
  'long-tailed chinchilla': {
    USDA: 'x',
    USDAException: '',
  },
  dog: {
    USDA: 'x',
    USDAException: '',
  },
  'domestic dog': {
    USDA: 'x',
    USDAException: '',
  },
  'domestic cat': {
    USDA: 'x',
    USDAException: '',
  },
  'domestic ferret': {
    USDA: 'x',
    USDAException: '',
  },
  'domestic pig': {
    USDA: 'x',
    USDAException: '',
  },
  pig: {
    USDA: 'x',
    USDAException: '',
  },
  'domestic rabbit': {
    USDA: 'x',
    USDAException: '',
  },
  donkey: {
    USDA: 'x',
    USDAException: '',
  },
  horse: {
    USDA: 'x',
    USDAException: '',
  },
  'domestic goat': {
    USDA: 'x',
    USDAException: '',
  },
  goat: {
    USDA: 'x',
    USDAException: '',
  },
  'domestic sheep': {
    USDA: 'x',
    USDAException: '',
  },
  sheep: {
    USDA: 'x',
    USDAException: '',
  },
  cattle: {
    USDA: 'x',
    USDAException: '',
  },
  'domestic cattle temperate-type ': {
    USDA: 'x',
    USDAException: '',
  },
  alpaca: {
    USDA: 'x',
    USDAException: '',
  },
  llama: {
    USDA: 'x',
    USDAException: '',
  },
  ' wood turtles': {
    USDA: '',
    USDAException: 'x',
  },
  'ball python': {
    USDA: '',
    USDAException: 'x',
  },
  'bearded dragon': {
    USDA: '',
    USDAException: 'x',
  },
  "blanding's turtle": {
    USDA: '',
    USDAException: 'x',
  },
  'carolina anole': {
    USDA: '',
    USDAException: 'x',
  },
  'common kingsnake': {
    USDA: '',
    USDAException: 'x',
  },
  'corn snake': {
    USDA: '',
    USDAException: 'x',
  },
  'wood turtle': {
    USDA: '',
    USDAException: 'x',
  },
  'western clawed frog': {
    USDA: '',
    USDAException: 'x',
  },
  'snapping turtle': {
    USDA: '',
    USDAException: 'x',
  },
  'scarlet kingsnake': {
    USDA: '',
    USDAException: 'x',
  },
  'snakes native to central wisconsin': {
    USDA: '',
    USDAException: 'x',
  },
  reptiles: {
    USDA: '',
    USDAException: 'x',
  },
  'red-eared slider turtle': {
    USDA: '',
    USDAException: 'x',
  },
  'painted turtle': {
    USDA: '',
    USDAException: 'x',
  },
  'african clawed frog': {
    USDA: '',
    USDAException: 'x',
  },
  'american toad': {
    USDA: '',
    USDAException: 'x',
  },
  amphibians: {
    USDA: '',
    USDAException: 'x',
  },
  'amphibians and reptiles native to southern wisconsin': {
    USDA: '',
    USDAException: 'x',
  },
  'eastern tiger salamander': {
    USDA: '',
    USDAException: 'x',
  },
  'northern leopard frog': {
    USDA: '',
    USDAException: 'x',
  },
  'pacific giant salamander': {
    USDA: '',
    USDAException: 'x',
  },
  'american flag fish': {
    USDA: '',
    USDAException: 'x',
  },
  angelfish: {
    USDA: '',
    USDAException: 'x',
  },
  anthias: {
    USDA: '',
    USDAException: 'x',
  },
  barbs: {
    USDA: '',
    USDAException: 'x',
  },
  blenny: {
    USDA: '',
    USDAException: 'x',
  },
  'blue danio': {
    USDA: '',
    USDAException: 'x',
  },
  bluegill: {
    USDA: '',
    USDAException: 'x',
  },
  blugill: {
    USDA: '',
    USDAException: 'x',
  },
  bonefish: {
    USDA: '',
    USDAException: 'x',
  },
  clownfish: {
    USDA: '',
    USDAException: 'x',
  },
  damselfish: {
    USDA: '',
    USDAException: 'x',
  },
  'corydorus catfish': {
    USDA: '',
    USDAException: 'x',
  },
  discus: {
    USDA: '',
    USDAException: 'x',
  },
  zebrafish: {
    USDA: '',
    USDAException: 'x',
  },
  'yellow lab cichlid': {
    USDA: '',
    USDAException: 'x',
  },
  'yellow perch': {
    USDA: '',
    USDAException: 'x',
  },
  'yellow tang': {
    USDA: '',
    USDAException: 'x',
  },
  'white cloud mountain minnow': {
    USDA: '',
    USDAException: 'x',
  },
  walleye: {
    USDA: '',
    USDAException: 'x',
  },
  trout: {
    USDA: '',
    USDAException: 'x',
  },
  tetras: {
    USDA: '',
    USDAException: 'x',
  },
  'the freshwater fishes of wisconsin': {
    USDA: '',
    USDAException: 'x',
  },
  'splitfin fish': {
    USDA: '',
    USDAException: 'x',
  },
  'spotted danio': {
    USDA: '',
    USDAException: 'x',
  },
  'siamese algae eater': {
    USDA: '',
    USDAException: 'x',
  },
  sculpin: {
    USDA: '',
    USDAException: 'x',
  },
  sauger: {
    USDA: '',
    USDAException: 'x',
  },
  'rainbow trout': {
    USDA: '',
    USDAException: 'x',
  },
  rasbora: {
    USDA: '',
    USDAException: 'x',
  },
  pumpkinseed: {
    USDA: '',
    USDAException: 'x',
  },
  platys: {
    USDA: '',
    USDAException: 'x',
  },
  'plecostomus and bristlenose catfish': {
    USDA: '',
    USDAException: 'x',
  },
  'fathead minnow': {
    USDA: '',
    USDAException: 'x',
  },
  fish: {
    USDA: '',
    USDAException: 'x',
  },
  'fishes native to hovsgol province': {
    USDA: '',
    USDAException: 'x',
  },
  'flame angel': {
    USDA: '',
    USDAException: 'x',
  },
  'panther danio': {
    USDA: '',
    USDAException: 'x',
  },
  'pearl danio': {
    USDA: '',
    USDAException: 'x',
  },
  otocinclus: {
    USDA: '',
    USDAException: 'x',
  },
  'orange finned danio': {
    USDA: '',
    USDAException: 'x',
  },
  'northern pike': {
    USDA: '',
    USDAException: 'x',
  },
  loaches: {
    USDA: '',
    USDAException: 'x',
  },
  'largemouth bass': {
    USDA: '',
    USDAException: 'x',
  },
  'indian pufferfish': {
    USDA: '',
    USDAException: 'x',
  },
  guppy: {
    USDA: '',
    USDAException: 'x',
  },
  'freshwater fishes': {
    USDA: '',
    USDAException: 'x',
  },
  'ghost catfish': {
    USDA: '',
    USDAException: 'x',
  },
  'goldfish/koi': {
    USDA: '',
    USDAException: 'x',
  },
  'green coral goby': {
    USDA: '',
    USDAException: 'x',
  },
  'american marten': {
    USDA: '',
    USDAException: 'x',
  },
  'barred owl': {
    USDA: '',
    USDAException: 'x',
  },
  'barred owl x spotted owl hybrid': {
    USDA: '',
    USDAException: 'x',
  },
  birds: {
    USDA: '',
    USDAException: 'x',
  },
  bobolink: {
    USDA: '',
    USDAException: 'x',
  },
  budgerigar: {
    USDA: '',
    USDAException: 'x',
  },
  'california spotted owl': {
    USDA: '',
    USDAException: 'x',
  },
  chicken: {
    USDA: '',
    USDAException: 'x',
  },
  'chimney swift': {
    USDA: '',
    USDAException: 'x',
  },
  'columbian sharp-tailed grouse': {
    USDA: '',
    USDAException: 'x',
  },
  'common starling': {
    USDA: '',
    USDAException: 'x',
  },
  'domestic chicken': {
    USDA: '',
    USDAException: 'x',
  },
  'zebra finch': {
    USDA: '',
    USDAException: 'x',
  },
  "steller's jays": {
    USDA: '',
    USDAException: 'x',
  },
  'domestic turkey': {
    USDA: '',
    USDAException: 'x',
  },
  'rock pigeon': {
    USDA: '',
    USDAException: 'x',
  },
  'ruby-throated hummingbird': {
    USDA: '',
    USDAException: 'x',
  },
  'ruffed grouse': {
    USDA: '',
    USDAException: 'x',
  },
  'savannah sparrow': {
    USDA: '',
    USDAException: 'x',
  },
  'eastern meadowlark': {
    USDA: '',
    USDAException: 'x',
  },
  'eurasian wryneck': {
    USDA: '',
    USDAException: 'x',
  },
  'exotic cavity nesting bird species': {
    USDA: '',
    USDAException: 'x',
  },
  fisher: {
    USDA: '',
    USDAException: 'x',
  },
  'free-living birds of ecuador': {
    USDA: '',
    USDAException: 'x',
  },
  'grasshopper sparrow': {
    USDA: '',
    USDAException: 'x',
  },
  'greater sage grouse': {
    USDA: '',
    USDAException: 'x',
  },
  'henslow‚äôs sparrow': {
    USDA: '',
    USDAException: 'x',
  },
  'house sparrow': {
    USDA: '',
    USDAException: 'x',
  },
  'japanese quail': {
    USDA: '',
    USDAException: 'x',
  },
  'marbled murrelet': {
    USDA: '',
    USDAException: 'x',
  },
  'passerine and non passerine landbirds': {
    USDA: '',
    USDAException: 'x',
  },
  'asian badger': {
    USDA: 'x',
    USDAException: '',
  },
  'big brown bat': {
    USDA: 'x',
    USDAException: '',
  },
  'black bear': {
    USDA: 'x',
    USDAException: '',
  },
  cheetah: {
    USDA: 'x',
    USDAException: '',
  },
  coyote: {
    USDA: 'x',
    USDAException: '',
  },
  'culpeo fox': {
    USDA: 'x',
    USDAException: '',
  },
  wolf: {
    USDA: 'x',
    USDAException: '',
  },
  wildlife: {
    USDA: 'x',
    USDAException: '',
  },
  'wild boar': {
    USDA: 'x',
    USDAException: '',
  },
  wapiti: {
    USDA: 'x',
    USDAException: '',
  },
  tiger: {
    USDA: 'x',
    USDAException: '',
  },
  'snowshoe hare': {
    USDA: 'x',
    USDAException: '',
  },
  'siberian roe deer': {
    USDA: 'x',
    USDAException: '',
  },
  'sika deer': {
    USDA: 'x',
    USDAException: '',
  },
  'south american grey fox': {
    USDA: 'x',
    USDAException: '',
  },
  'red fox': {
    USDA: 'x',
    USDAException: '',
  },
  raccoon: {
    USDA: 'x',
    USDAException: '',
  },
  'racoon dog': {
    USDA: 'x',
    USDAException: '',
  },
  puma: {
    USDA: 'x',
    USDAException: '',
  },
  porcupine: {
    USDA: 'x',
    USDAException: '',
  },
  'eastern cottontail rabbit': {
    USDA: 'x',
    USDAException: '',
  },
  fox: {
    USDA: 'x',
    USDAException: '',
  },
  'pacific marten': {
    USDA: 'x',
    USDAException: '',
  },
  'frugivorous bats': {
    USDA: 'x',
    USDAException: '',
  },
  'german ram': {
    USDA: 'x',
    USDAException: '',
  },
  'insectivorous bats': {
    USDA: 'x',
    USDAException: '',
  },
  'little brown bat': {
    USDA: 'x',
    USDAException: '',
  },
  'musk deer': {
    USDA: 'x',
    USDAException: '',
  },
  'big-eared woodrat': {
    USDA: '',
    USDAException: 'x',
  },
  'bushy-tailed woodrat': {
    USDA: '',
    USDAException: 'x',
  },
  'california mouse': {
    USDA: '',
    USDAException: 'x',
  },
  'california vole': {
    USDA: '',
    USDAException: 'x',
  },
  'california ground squirrel': {
    USDA: 'x',
    USDAException: '',
  },
  chipmunk: {
    USDA: 'x',
    USDAException: '',
  },
  'chipmunk species': {
    USDA: 'x',
    USDAException: '',
  },
  'common shrew': {
    USDA: '',
    USDAException: 'x',
  },
  'cotton mouse': {
    USDA: '',
    USDAException: 'x',
  },
  'cotton rat': {
    USDA: '',
    USDAException: 'x',
  },
  'deer mouse': {
    USDA: '',
    USDAException: 'x',
  },
  'woodland jumping mouse': {
    USDA: '',
    USDAException: 'x',
  },
  woodrat: {
    USDA: '',
    USDAException: 'x',
  },
  'eastern chipmunk': {
    USDA: 'x',
    USDAException: '',
  },
  'white footed mouse': {
    USDA: '',
    USDAException: 'x',
  },
  'white-footed mouse': {
    USDA: '',
    USDAException: 'x',
  },
  'western flying squirrel': {
    USDA: 'x',
    USDAException: '',
  },
  'western grey squirrel': {
    USDA: 'x',
    USDAException: '',
  },
  'western harvest mouse': {
    USDA: '',
    USDAException: 'x',
  },
  'southern flying squirrel': {
    USDA: 'x',
    USDAException: '',
  },
  'short tailed shrew': {
    USDA: '',
    USDAException: 'x',
  },
  'red squirrel': {
    USDA: 'x',
    USDAException: '',
  },
  'red-backed vole': {
    USDA: '',
    USDAException: 'x',
  },
  'prairie vole': {
    USDA: '',
    USDAException: 'x',
  },
  'dusky-footed woodrat': {
    USDA: '',
    USDAException: 'x',
  },
  'eastern harvest mouse': {
    USDA: '',
    USDAException: 'x',
  },
  'eastern woodrat': {
    USDA: '',
    USDAException: 'x',
  },
  'house mouse': {
    USDA: '',
    USDAException: 'x',
  },
  'masked shrew': {
    USDA: '',
    USDAException: 'x',
  },
  'meadow vole': {
    USDA: '',
    USDAException: 'x',
  },
  'mountain vole': {
    USDA: '',
    USDAException: 'x',
  },
  'northern flying squirrel': {
    USDA: 'x',
    USDAException: '',
  },
  'northern short tailed shrew': {
    USDA: '',
    USDAException: 'x',
  },
  'northern short-tailed shrew': {
    USDA: '',
    USDAException: 'x',
  },
  'oldfield mouse': {
    USDA: '',
    USDAException: 'x',
  },
  'red backed vole': {
    USDA: '',
    USDAException: 'x',
  },
  'black-and-white colobus': {
    USDA: 'x',
    USDAException: '',
  },
  'blue guenon': {
    USDA: 'x',
    USDAException: '',
  },
  'common marmoset': {
    USDA: 'x',
    USDAException: '',
  },
  'cynomolgus macaque': {
    USDA: 'x',
    USDAException: '',
  },
  vervet: {
    USDA: 'x',
    USDAException: '',
  },
  'rhesus macaque': {
    USDA: 'x',
    USDAException: '',
  },
  'red-tailed guenon': {
    USDA: 'x',
    USDAException: '',
  },
  'red colobus': {
    USDA: 'x',
    USDAException: '',
  },
  'l‚äôhoest guenon': {
    USDA: 'x',
    USDAException: '',
  },
  'grey-cheeked mangabey': {
    USDA: 'x',
    USDAException: '',
  },
  macaque: {
    USDA: 'x',
    USDAException: '',
  },
  'olive baboon': {
    USDA: 'x',
    USDAException: '',
  },
  'interacting rodents': {
    USDA: '',
    USDAException: '',
  },
  mammals: {
    USDA: 'x',
    USDAException: '',
  },
  'other mammals': {
    USDA: 'x',
    USDAException: '',
  },
  'small mammals': {
    USDA: 'x',
    USDAException: '',
  },
  'small mammals native to sierra nevada': {
    USDA: 'x',
    USDAException: '',
  },
  'vertebrate species native to wisconsin': {
    USDA: 'x',
    USDAException: '',
  },
  'vertebrate wildlife species found in wisconsin': {
    USDA: 'x',
    USDAException: '',
  },
};

export const USDAAnimalList = [
  'guinea pig',
  'hedgehog',
  'chinchilla',
  'dog',
  'dogs',
  'cat',
  'cats',
  'ferret',
  'ferrets',
  'pigs',
  'rabbit',
  'rabbits',
  'donkey',
  'goats',
  'bat',
  'bear',
  'cheetah',
  'fox',
  'boar',
  'hare',
  'deer',
  'ram',
  'squirrel',
  'chipmunk',
  'macaque',
  'USDA-Covered Mice',
  'USDA-Covered Rats',
];

export const USDAExceptionList = [
  'rat',
  'rats',
  'mouse',
  'mice',
  'hamster',
  'hamsters',
  'gerbil',
  'gerbils',
  'turtle',
  'turtles',
  'snake',
  'snakes',
  'frog',
  'frogs',
  'toad',
  'toads',
  'fish',
  'chicken',
  'turkey',
];
