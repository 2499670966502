import React from 'react';

import {
  Table,
  LinearProgress,
  TextField,
  Grid,
  Snackbar,
  Button,
  Alert,
  AlertTitle,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  IconButton,
  Badge,
  Tooltip,
  Box,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import AddIcon from '@mui/icons-material/AddCircle';
import CartIcon from '@mui/icons-material/ShoppingCart';
import { KeyboardArrowLeft } from '@mui/icons-material';
import { useQuery, useMutation } from '@apollo/client';
import { gql } from '@apollo/client';
import StandardWrapper from '../components/StandardWrapper';
import AddInventoryItemToCart from './AddInventoryItemToCart';
import AddAbstractInventoryItemToCart from './AddAbstractInventoryItemToCart';
import InventoryCart from './InventoryCart';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'name', numeric: false, disablePadding: true, label: 'Name' },
  { id: 'unit', numeric: false, disablePadding: false, label: 'Unit' },
  { id: 'vendor', numeric: false, disablePadding: false, label: 'Vendor' },
  { id: 'code', numeric: false, disablePadding: false, label: 'Item No.' },
  { id: 'description', numeric: false, disablePadding: false, label: 'Description' },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell />
      </TableRow>
    </TableHead>
  );
}

const EnhancedTableToolbar = (props) => {
  const { filterInput, onFilterInputChange, onFreeFill } = props;

  return (
    <Toolbar>
      <Grid container justifyContent="space-between">
        <Grid item xs={12}>
          <Typography variant="h4" id="tableTitle" component="div" style={{ marginBottom: 50 }}>
            Select Supplies
          </Typography>
        </Grid>
        <Grid item>
          <Button aria-label="Free fill item" size="small" style={{ color: '#9b0000' }} onClick={onFreeFill}>
            I don't see my item
          </Button>
        </Grid>
        <Grid item>
          <TextField
            value={filterInput}
            onChange={(e) => onFilterInputChange(e.target.value)}
            placeholder="Filter"
            size="small"
          />
        </Grid>
      </Grid>
    </Toolbar>
  );
};

const GET_INVENTORY_ITEMS = gql`
  query InventoryItems {
    inventoryItems {
      _id
      name
      unit
      vendor
      code
      description
    }
  }
`;

const GET_INVENTORY_CART = gql`
  query {
    userInventoryCart {
      items {
        quantity
        item {
          _id
          name
          unit
          vendor
          code
          description
        }
      }
    }
  }
`;

const processRows = ({ data, filterInput }) => {
  if (!data || !data.inventoryItems) {
    return [];
  }
  const { inventoryItems } = data;
  if (!Array.isArray(inventoryItems)) {
    console.error('Notification conditions on provided data set is not an array');
    return [];
  }
  const keys = ['_id', 'name', 'unit', 'vendor', 'code', 'description'];
  let res = inventoryItems.map((x) => {
    const obj = {
      ...x,
    };
    if (!obj._id) {
      throw new Error(`Data object has no _id. Obj: ${JSON.stringify(x)}`);
    }
    keys.forEach((key) => {
      if (!obj[key]) {
        obj[key] = '';
      }
    });
    return obj;
  });
  if (typeof filterInput === 'string') {
    return res.filter((x) => {
      let query = filterInput.toLowerCase();
      for (let i = 0; i < keys.length; i++) {
        if (typeof x[keys[i]] === 'string' && x[keys[i]].toLowerCase().includes(query)) {
          return true;
        }
      }
      return false;
    });
  }
  return res;
};

export default function InventoryItemsTable({ user }) {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('name');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [filterInput, setFilterInput] = React.useState('');
  const [selected, setSelected] = React.useState();
  const [abstract, setAbstract] = React.useState(false);
  const [cartOpen, setCartOpen] = React.useState(false);
  const [toast, setToast] = React.useState(false);

  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToast(false);
  };

  const { loading: cartLoading, error: cartError, data: cartData, refetch: refetchCart } = useQuery(GET_INVENTORY_CART);
  console.log('cartData: ', cartData);

  const { loading, error, data, refetch } = useQuery(GET_INVENTORY_ITEMS);

  const rows = processRows({ data, filterInput });

  const handleRequestSort = (event, property) => {
    console.log('property: ', property);

    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const getBadgeContent = () => {
    if (
      cartData &&
      cartData.userInventoryCart &&
      Array.isArray(cartData.userInventoryCart.items) &&
      cartData.userInventoryCart.items.length > 0
    ) {
      return cartData.userInventoryCart.items.length;
    }
    return null;
  };

  if (selected) {
    return (
      <StandardWrapper>
        <AddInventoryItemToCart
          inventoryItem={selected}
          onClose={() => {
            setSelected();
          }}
          onSubmit={() => {
            refetchCart();
            setSelected();
            setToast('Item added to your cart!');
          }}
          user={user}
        />
      </StandardWrapper>
    );
  }
  if (abstract) {
    return (
      <StandardWrapper>
        <AddAbstractInventoryItemToCart
          onClose={() => {
            setAbstract(false);
          }}
          onSubmit={() => {
            refetchCart();
            setAbstract(false);
            setToast('Item added to your cart!');
          }}
          user={user}
        />
      </StandardWrapper>
    );
  }
  if (cartOpen) {
    const cart = { ...cartData.userInventoryCart };
    console.log('cart: ', cart);

    if (!Array.isArray(cart.items)) {
      cart.items = [];
    }
    return (
      <InventoryCart
        user={user}
        cart={cart}
        refetch={refetchCart}
        onClose={() => {
          setCartOpen(false);
        }}
        onSubmit={() => {
          setCartOpen(false);
          setToast('Your order has been placed!');
        }}
      />
    );
  }

  return (
    <StandardWrapper>
      <Grid container justifyContent="space-between">
        <Grid item>
          <IconButton
            aria-label="back to tool selection"
            onClick={() => {
              window.location.href = '/inventory-orders';
            }}
          >
            <KeyboardArrowLeft />
          </IconButton>
        </Grid>
      </Grid>
      {loading ? (
        <LinearProgress />
      ) : (
        <Grid container>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <IconButton
                onClick={() => {
                  setCartOpen(!cartLoading && true);
                }}
              >
                <Badge badgeContent={getBadgeContent()} color="primary">
                  <CartIcon fontSize="large" />
                </Badge>
              </IconButton>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <EnhancedTableToolbar
              filterInput={filterInput}
              onFilterInputChange={setFilterInput}
              onFreeFill={() => {
                setAbstract(true);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TableContainer>
              <Table aria-labelledby="tableTitle" aria-label="enhanced table">
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />
                <TableBody>
                  {stableSort(rows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow hover tabIndex={-1} key={row._id}>
                          <TableCell component="th" id={labelId} scope="row" padding="none">
                            {row.name}
                          </TableCell>
                          <TableCell align="left">{row.unit}</TableCell>
                          <TableCell align="left">{row.vendor}</TableCell>
                          <TableCell align="left">{row.code}</TableCell>
                          <TableCell align="left">{row.description}</TableCell>
                          <TableCell>
                            <Tooltip title="Add Item">
                              <IconButton
                                aria-label="add item"
                                onClick={() => {
                                  setSelected(row);
                                }}
                              >
                                <AddIcon fontSize="large" color="primary" />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 33 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Grid>
        </Grid>
      )}
      <Snackbar open={toast} autoHideDuration={6000} onClose={handleToastClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
        <Alert onClose={handleToastClose} variant="filled" severity="success">
          <AlertTitle>Success</AlertTitle>
          {toast}
        </Alert>
      </Snackbar>
    </StandardWrapper>
  );
}
