/* eslint-disable no-use-before-define */
import React, { useState } from 'react';

import { Autocomplete, createFilterOptions, CircularProgress, InputAdornment, TextField } from '@mui/material';
import { Error } from '@mui/icons-material';

import { gql } from '@apollo/client';
import { useQuery } from '@apollo/client';

import { isAuthError } from '../utils';

const GET_ALL_PROTOCOL_OUTLINES = gql`
  {
    allProtocolOutlines {
      _id
      protocolNumber
      label
      PIPVI
      buildings
      species
    }
  }
`;
const loadingProps = {
  startAdornment: (
    <InputAdornment position="start">
      <CircularProgress />
    </InputAdornment>
  ),
};

const errorProps = {
  startAdornment: (
    <InputAdornment position="start">
      <Error />
    </InputAdornment>
  ),
};

const filterOptions = createFilterOptions({
  limit: 5,
});

const ProtocolSearch = ({ name, val = '', onSelectProtocol, sendInput = console.log, required = true, fullWidth }) => {
  const [protocols, setProtocols] = useState();
  const { loading, error, data } = useQuery(GET_ALL_PROTOCOL_OUTLINES);
  // const location = useLocation();
  if (!loading && data && !protocols) {
    const { allProtocolOutlines } = data;
    if (Array.isArray(allProtocolOutlines) && allProtocolOutlines.length > 0) {
      setProtocols(allProtocolOutlines);
    }
  } else if (error) {
    if (isAuthError(error)) {
      window.location.href = '/login';
    }
    console.error('Error fetching protocols in ProtocolSearch Component');
    console.error(error);
    // throw error
  }

  const handleSelect = (e, value) => {
    if (value) {
      sendInput(name, value.protocolNumber);
    }
    onSelectProtocol(value);
  };

  return (
    <Autocomplete
      id="protocol-outline-search"
      filterOptions={filterOptions}
      options={protocols ? protocols : []}
      getOptionLabel={(option) => option.label}
      onChange={handleSelect}
      renderInput={(params) => {
        // if (params.inputProps) {
        //     params.inputProps.value = val
        // }
        return protocols ? (
          <TextField
            {...params}
            required={required}
            label="Protocol"
            variant="outlined"
            placeholder="Enter protocol"
            value={val}
            onKeyDown={(e) => {
              if (e.keyCode === 13)
                //Enter
                e.preventDefault();
            }}
            fullWidth={fullWidth}
          />
        ) : (
          <TextField
            {...params}
            required
            label="Protocol"
            variant="outlined"
            placeholder="Enter protocol"
            InputProps={loading ? loadingProps : errorProps}
            value={val}
            fullWidth={fullWidth}
            onKeyDown={(e) => {
              if (e.keyCode === 13)
                //Enter
                e.preventDefault();
            }}
          />
        );
      }}
    />
  );
};

export default ProtocolSearch;
