import React, { useEffect, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import {
  QUERY_RESOURCES,
  QUERY_RESERVATIONS,
  QUERY_USER_PROTOCOLS,
  ADD_RESERVATION,
  UPDATE_RESERVATION,
  UPDATE_OCCURRENCE,
  CANCEL_RESERVATION,
  CREATE_ERROR,
} from './graphql';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import ReservationForm from './ResourceReservationForm';
import {
  Autocomplete,
  TextField,
  Button,
  Select,
  MenuItem,
  Box,
  LinearProgress,
  Dialog,
  Snackbar,
  Alert,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Grid,
  FormHelperText,
  ToggleButtonGroup,
  ToggleButton,
  Tooltip,
  Chip,
  Typography,
} from '@mui/material/';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import { indigo, purple, teal, green } from '@mui/material/colors';
import ViewWeekIcon from '@mui/icons-material/ViewWeek';
import ViewStreamIcon from '@mui/icons-material/ViewStream';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';

import { ViewState, GroupingState, IntegratedGrouping } from '@devexpress/dx-react-scheduler';
import {
  MonthView,
  WeekView,
  Scheduler,
  DayView,
  Appointments,
  Toolbar,
  ViewSwitcher,
  DateNavigator,
  TodayButton,
  AppointmentTooltip,
  CurrentTimeIndicator,
  Resources,
  GroupingPanel,
} from '@devexpress/dx-react-scheduler-material-ui';
import { buildingCodeToName } from '../utils';
import { parseRRuleString } from './utils';
import StandardWrapper from '../components/StandardWrapper';

const theme = createTheme({
  typography: {
    h1: {
      fontFamily: 'Red Hat Display, sans-serif',
      fontWeight: 800,
      fontSize: '2rem',
      lineHeight: '2.5rem',
      paddingBlock: '.5em',
    },
  },
  palette: {
    primary: {
      main: '#c5050c',
      contrastText: '#fff',
    },

    resource1: { main: indigo[400], contrastText: '#fff' },
    resource2: { main: purple[400], contrastText: '#fff' },
    resource3: { main: indigo[600], contrastText: '#fff' },
    resource4: { main: purple[600], contrastText: '#fff' },
    resource5: { main: indigo[800], contrastText: '#fff' },
    resource6: { main: purple[800], contrastText: '#fff' },
    resource7: { main: indigo[900], contrastText: '#fff' },
    resource8: { main: purple[900], contrastText: '#fff' },

    protocol1: { main: teal[400], contrastText: '#fff' },
    protocol2: { main: green[400], contrastText: '#fff' },
    protocol3: { main: teal[600], contrastText: '#fff' },
    protocol4: { main: green[600], contrastText: '#fff' },
    protocol5: { main: teal[800], contrastText: '#fff' },
    protocol6: { main: green[800], contrastText: '#fff' },
    protocol7: { main: teal[900], contrastText: '#fff' },
    protocol8: { main: green[900], contrastText: '#fff' },
  },
});

const ResourceScheduler = ({ user }) => {
  /* ------------------------------- */
  /* Component State --------------- */
  /* ------------------------------- */

  const { resourceId } = useParams();

  // Used by Scheduler for resource organization
  const [schedulerCategories, setSchedulerCategories] = useState([
    { fieldName: 'resource', title: 'Resources', instances: [] },
    { fieldName: 'protocol', title: 'Protocols', instances: [] },
  ]);

  const [tooltipClosed, setTooltipClosed] = useState(false);

  // Current text in resources autocomplete
  const [resourceInput, setResourceInput] = useState('');

  // BRMS Resources currently selected
  const [selectedResources, setSelectedResources] = useState([]);

  // View in groups
  const [groupingOn, setGroupingOn] = useState(false);
  const [groupOrientation, setGroupOrientation] = useState('');

  // Calendar Start Time
  const [calendarStart, setCalendarStart] = useState(7);
  // Calendar End Time
  const [calendarEnd, setCalendarEnd] = useState(18);

  const [open, setOpen] = useState(false);
  const [editingAppointment, setEditingAppointment] = useState({});
  const [isCancelling, setIsCancelling] = useState(false);

  // Feedback
  const [successMessage, setSuccessMessage] = useState('');
  const [errMessage, setErrMessage] = useState('');

  // Submission loading // workaround for freezing issue
  const [submissionLoading, setSubmissionLoading] = useState(false);

  /* ---------------------------- */
  /* API Calls ------------------ */
  /* ---------------------------- */

  const { loading: resourceLoading, data: resourceData } = useQuery(QUERY_RESOURCES);

  const {
    loading: reservationLoading,
    data: reservationData,
    refetch: refetchReservations,
    startPolling,
    stopPolling,
  } = useQuery(QUERY_RESERVATIONS, {
    variables: { resources: selectedResources.forEach((x) => x._id) }, // right now this will be empty but with smart links it will come in handy
    pollInterval: 1000,
  });

  const { loading: protocolsLoading, data: protocolsData } = useQuery(QUERY_USER_PROTOCOLS);

  const [createError] = useMutation(CREATE_ERROR);

  const onError = (e, action, data) => {
    createError({
      variables: {
        PVI: user.PVI,
        action,
        error: e ? JSON.stringify(e) : undefined,
        data: JSON.stringify({ ...data }),
      },
    });
    setErrMessage(
      'Something went wrong. The BRMS IT office has received an error report and will contact you shortly.'
    );
  };

  const onAdded = () => {
    handleShowForm(false);
    // renderReservations(selectedResources);
    refetchReservations({ resources: selectedResources.map((x) => x._id) });
    setSuccessMessage('Reservation Added!');
  };
  const onUpdated = () => {
    handleShowForm(false);
    refetchReservations({ resources: selectedResources.map((x) => x._id) });
    setSuccessMessage('Reservation Updated!');
  };
  const onCancelled = () => {
    handleShowForm(false);
    refetchReservations({ resources: selectedResources.map((x) => x._id) });
    setSuccessMessage('Reservation Cancelled!');
    setIsCancelling(false);
  };

  const [addReservation, { data: addData, loading: addLoading, error: addError }] = useMutation(ADD_RESERVATION, {
    refetchQueries: [QUERY_RESERVATIONS, 'resourceReservations'],
    onCompleted() {
      // Add mutation is used when updating a single occurrence
      if (editingAppointment.id) {
        onUpdated();
      } else {
        onAdded();
      }
      setSubmissionLoading(false);
    },
    onError: (e) => {
      onError(e, 'addReservation', { user, editingAppointment, addData, addError });
      setSubmissionLoading(false);
    },
  });

  const [updateReservation, { data: updateData, loading: updateLoading, error: updateError }] = useMutation(
    UPDATE_RESERVATION,
    {
      refetchQueries: [QUERY_RESERVATIONS, 'resourceReservations'],
      onCompleted() {
        onUpdated();
        setSubmissionLoading(false);
      },
      onError: (e) => {
        onError(e, 'updateReservation', { user, editingAppointment, updateData, updateError });
        setSubmissionLoading(false);
      },
    }
  );

  const [updateOccurrence, { data: updateOccData, loading: updateOccLoading, error: updateOccError }] = useMutation(
    UPDATE_OCCURRENCE,
    {
      refetchQueries: [QUERY_RESERVATIONS, 'resourceReservations'],
      onCompleted() {
        onUpdated();
        setSubmissionLoading(false);
      },
      onError: (e) => {
        onError(e, 'updateSingleOccurrence', { user, editingAppointment, updateOccData, updateOccError });
        setSubmissionLoading(false);
      },
    }
  );

  const [cancelReservation, { data: cancelData, loading: cancelLoading, error: cancelError }] = useMutation(
    CANCEL_RESERVATION,
    {
      refetchQueries: [QUERY_RESERVATIONS, 'resourceReservations'],
      onCompleted() {
        // Cancel mutation is used when updating a single occurrence
        if (isCancelling) {
          onCancelled();
          setSubmissionLoading(false);
        }
      },
      onError: (e) => {
        onError(e, 'cancelReservation', { user, editingAppointment, cancelData, cancelError });
        setSubmissionLoading(false);
      },
    }
  );

  // update available protocols
  useEffect(() => {
    const protocolsArray = protocolsData?.userProtocols || [];

    const updatedApptResources = schedulerCategories;

    const instanceArray = protocolsArray.map((protocol) => {
      return { id: protocol.protocolNumber, text: protocol.label };
    });
    instanceArray.push({ id: null, text: ' none ' });
    updatedApptResources.find((apptResource) => apptResource.title === 'Protocols').instances = instanceArray;
    setSchedulerCategories([...updatedApptResources]);
  }, [protocolsData]);

  // Populate calendar with resource from parameter
  useEffect(() => {
    if (resourceId && resourceData) {
      const resource = resourceData.resources.find((resource) => resource._id === resourceId);
      if (resource) {
        setSelectedResources([resource]);
        handleResourceUpdate([resource]);
      } else {
        // ? potentially redirect to non-parametered url
      }
    }
  }, [resourceData]);

  /* ---------------------------- */
  /* Helper Functions ----------- */
  /* ---------------------------- */

  // Array of colors to use for resources and chips
  const resourcePalette = [
    theme.palette.resource1.main,
    theme.palette.resource2.main,
    theme.palette.resource3.main,
    theme.palette.resource4.main,
    theme.palette.resource5.main,
    theme.palette.resource6.main,
    theme.palette.resource7.main,
    theme.palette.resource8.main,
  ];

  // Detect touchscreen device
  const isTouchscreen = window.matchMedia('(hover: none) and (pointer: coarse)');

  // Detect narrow width device
  const isNarrowscreen = window.matchMedia('(max-width: 600px');

  // Set permissions for editing based on user group
  const canEditReservation = (reservation) => {
    // Admins and BRMS superusers can edit any reservation
    if (user && Array.isArray(user.groups) && ['admin', 'brmsSuperuser'].some((group) => user.groups.includes(group))) {
      return true;
    }

    // Don't let standard users edit events in the past
    // if (moment(reservation.startDate).unix() < moment().unix()) {
    //   return false;
    // }

    // // Can edit a reservation that they themselves created
    if (reservation.createdBy && reservation.createdBy.PVI && user.PVI === reservation.createdBy.PVI) {
      return true;
    }

    // Can edit reservations for protocols that they are on
    if (Array.isArray(protocolsData.userProtocols)) {
      const protocolNumbers = protocolsData.userProtocols.map((x) => x.protocolNumber);
      if (reservation.protocol && protocolNumbers.includes(reservation.protocol)) {
        return true;
      }
    }

    return false;
  };

  // Takes array of resource objects and parses it into an object the React Scheduler can interpret
  const updateSchedulerResources = (selectedResources) => {
    const updatedApptResources = schedulerCategories;

    const instanceArray = selectedResources.map((resource, index) => {
      const muiThemeColor = Object.entries(theme.palette)[(index % resourcePalette.length) + 2][0];

      return {
        id: resource._id,
        text: resource.resourceName,
        color: muiThemeColor.main,
      };
    });
    updatedApptResources.find((apptResource) => apptResource.title === 'Resources').instances = instanceArray;

    setSchedulerCategories([...updatedApptResources]);
  };

  // Update calendar reservations based on selected resources
  const getReservations = () => {
    const reservations =
      reservationData && Array.isArray(reservationData.resourceReservations)
        ? [...reservationData.resourceReservations]
        : [];

    return reservations.map((reservation) => {
      const {
        _id: id,
        title,
        resource: { _id: resource },
        rRule,
        exDate,
        protocol,
      } = reservation;
      const startDate = new Date(reservation.startDate);
      const endDate = new Date(reservation.endDate);
      const canEdit = canEditReservation(reservation);
      const result = {
        id,
        title,
        resource,
        startDate,
        endDate,
        rRule,
        exDate,
        canEdit,
        protocol,
      };
      return result;
    });
  };

  // Update calendar reservations based on selected resources
  // const renderReservations = async (selectedResources) => {
  //   setTooltipsForceClosed(false);
  //   // extract selected resource IDs
  //   const resourceIds = selectedResources.map((resource) => resource._id);
  //   // fetch reservations based on selected resources
  //   const reservationResponse = await queryReservations({
  //     variables: { resources: resourceIds },
  //   });
  //   let reservations = reservationResponse.data.resourceReservations;

  //   reservations = reservations.map((reservation) => {
  //     const {
  //       _id: id,
  //       title,
  //       resource: { _id: resource },
  //       rRule,
  //       exDate,
  //       protocol,
  //     } = reservation;
  //     const startDate = new Date(reservation.startDate);
  //     const endDate = new Date(reservation.endDate);
  //     const canEdit = canEditReservation(reservation);
  //     const result = {
  //       id,
  //       title,
  //       resource,
  //       startDate,
  //       endDate,
  //       rRule,
  //       exDate,
  //       canEdit,
  //       protocol,
  //     };
  //     return result;
  //   });

  //   // update reservations on calendar for render
  //   setActiveReservations(reservations);
  // };

  /* ---------------------------- */
  /* Event Handlers ------------- */
  /* ---------------------------- */

  // Runs onChange of Autocomplete component or resource is a url param
  const handleResourceUpdate = async (resources) => {
    // await renderReservations(resources);
    updateSchedulerResources(resources);
    setSelectedResources(resources);
    stopPolling();
    refetchReservations({ resources: resources.map((x) => x._id) });
    startPolling(5000);
    setResourceInput('');
  };

  // Runs onClick of group orientation ToggleButtonGroup
  const handleGroupOrientation = (event, value) => {
    setGroupOrientation(value);

    !value ? setGroupingOn(false) : setGroupingOn(true);
  };

  // Runs onChange of start/end time Select
  const setStartTime = (e) => {
    setCalendarStart(e.target.value);
  };
  const setEndTime = (e) => {
    setCalendarEnd(e.target.value);
  };

  // Runs when forms are opened
  const handleShowForm = (open) => {
    if (!open) {
      setIsCancelling(false);
    }
    setTooltipClosed(open);
    setOpen(open);
  };

  const resourceMatchesInput = (resource, input) => {
    const { resourceName, resourceType, building } = resource;
    const nameMatch = resourceName.toLowerCase().includes(input);
    // Allow matching of building code and name
    const buildingMatch =
      building.toLowerCase().includes(input) ||
      (buildingCodeToName(building) && buildingCodeToName(building).toLowerCase().includes(input));
    const typeMatch = resourceType.toLowerCase().includes(input);
    return nameMatch || buildingMatch || typeMatch;
  };

  function compareResources(a, b) {
    if (a.resourceName < b.resourceName) {
      return -1;
    }
    if (a.resourceName > b.resourceName) {
      return 1;
    }
    return 0;
  }

  const filterInput = (options) => {
    let res = [];
    if (Array.isArray(options)) {
      if (!resourceInput) {
        res = options;
      }
      const input = resourceInput.toLocaleLowerCase();
      res = options.filter((x) => {
        // Check if text directly matches an attribute
        const exactMatch = resourceMatchesInput(x, input);
        if (exactMatch) return true;
        // Otherwise see if we can break up input into useful pieces that all individually match
        // e.g. BSC B12345 should return WIMR B12345 BSC even though it is out of order for the name
        const splitInput = input.split(' ');
        if (splitInput.length > 1) {
          return splitInput.filter((y) => resourceMatchesInput(x, y)).length === splitInput.length;
        }
        return false;
      });
    } else {
      return [];
    }

    // sort by name before returning
    return res.sort(compareResources);
  };

  const getResourceColor = (index) => {
    const color = Object.entries(theme.palette)[(index % resourcePalette.length) + 2][0];
    if (color) return color;
    else {
      return theme.palette.primary;
    }
  };

  const handleSelectRange = (startDate, endDate) => {
    handleShowForm(true);
    setEditingAppointment({
      startDate,
      endDate,
    });
  };

  const handleOpenAppointment = (data) => {
    const { startDate, endDate, rRule, resource, id, title, exDate, canEdit = false, protocol } = data;
    if (!canEdit) {
      return;
    }
    const appointment = {
      startDate,
      endDate,
      resource,
      id,
      title,
      exDate,
      protocol,
    };
    if (rRule) {
      appointment.rRule = parseRRuleString(rRule);
    }

    setEditingAppointment(appointment);
    handleShowForm(true);
  };

  const handleDelete = (data) => {
    if (data.canEdit) {
      setIsCancelling(true);
      handleOpenAppointment(data);
    }
  };

  /* ---------------------- */
  /* Subcomponents -------- */
  /* ---------------------- */
  const CustomToolbar = ({ children, ...restProps }) => {
    const timeList = [
      '12AM',
      '1AM',
      '2AM',
      '3AM',
      '4AM',
      '5AM',
      '6AM',
      '7AM',
      '8AM',
      '9AM',
      '10AM',
      '11AM',
      '12PM',
      '1PM',
      '2PM',
      '3PM',
      '4PM',
      '5PM',
      '6PM',
      '7PM',
      '8PM',
      '9PM',
      '10PM',
      '11PM',
      '12AM',
    ];

    // Set sensible start and end times based on current time
    const handleReserveButton = () => {
      const currentHour = moment().hour();
      // Weird logic avoids advancing the date by passing midnight
      const startHour = currentHour < 22 ? currentHour + 1 : 22;
      const endHour = startHour + 1;
      const startMoment = moment().startOf('day').hour(startHour);
      // Month view defaults end date to the next day, set it with the start date instead
      const endMoment = moment().startOf('day').hour(endHour);
      handleSelectRange(startMoment, endMoment);
    };

    return (
      <Toolbar.Root {...restProps}>
        <Grid container>
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-start', padding: '.5em', alignItems: 'center' }}>
              {children}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',

                padding: '.5em',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  alignItems: 'center',
                  gap: '.5em .5em',
                  justifyContent: 'center',
                }}
              >
                {/* {selectedResources.length > 1 && ( */}
                <Tooltip title="View calendars side-by-side or stacked.">
                  <ToggleButtonGroup
                    value={selectedResources.length <= 1 ? null : groupOrientation}
                    exclusive
                    onChange={handleGroupOrientation}
                    disabled={selectedResources.length <= 1}
                  >
                    <ToggleButton value="Horizontal">
                      <ViewWeekIcon />
                    </ToggleButton>
                    <ToggleButton value="Vertical">
                      <ViewStreamIcon />
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Tooltip>
                {/* } */}

                <Tooltip title="Adjust the start and end time of the calendar.">
                  <Box sx={{ display: 'inline' }}>
                    <Select value={calendarStart} onChange={setStartTime} size="small">
                      {timeList
                        .filter((time, index) => {
                          return index < calendarEnd;
                        })
                        .map((time, index) => (
                          <MenuItem key={index} value={index}>
                            {time}
                          </MenuItem>
                        ))}
                    </Select>

                    <Select defaultValue={calendarEnd} onChange={setEndTime} size="small">
                      {timeList.map(
                        (time, index) =>
                          index > calendarStart && (
                            <MenuItem key={index} value={index}>
                              {time}
                            </MenuItem>
                          )
                      )}
                    </Select>
                  </Box>
                </Tooltip>
              </Box>

              <Button size="small" variant="contained" onClick={handleReserveButton}>
                Reserve Resource
              </Button>
            </Box>
          </Grid>
          {!isTouchscreen.matches && (
            <Grid item xs={12}>
              <FormHelperText sx={{ marginTop: 2, marginBottom: 1, textAlign: 'center' }}>
                Double click an empty space on the calendar to make a reservation.
              </FormHelperText>
            </Grid>
          )}
        </Grid>
      </Toolbar.Root>
    );
  };

  const CustomAppointment = (props) => {
    return <Appointments.Appointment {...props} onDoubleClick={() => handleOpenAppointment(props.data)} />;
  };

  const CustomAppointmentTooltipLayout = (props) => {
    let canEdit = false;
    if (props && props.appointmentMeta && props.appointmentMeta.data) {
      canEdit = props.appointmentMeta.data.canEdit;
    }
    return (
      <AppointmentTooltip.Layout
        {...props}
        key={props.appointmentMeta ? JSON.stringify(props.appointmentMeta.data) : undefined}
        showOpenButton={canEdit}
        showDeleteButton={canEdit}
        onOpenButtonClick={() => handleOpenAppointment(props.appointmentMeta.data)}
        onDeleteButtonClick={() => handleDelete(props.appointmentMeta.data)}
      />
    );
  };

  /* Custom TimeTable Cell subcomponent  */
  const CustomTimeTableCellComponent = (view) => (props) => {
    if (view === 'week') {
      return <WeekView.TimeTableCell onDoubleClick={() => handleSelectRange(props.startDate, props.endDate)} />;
    }
    if (view === 'day') {
      return <DayView.TimeTableCell onDoubleClick={() => handleSelectRange(props.startDate, props.endDate)} />;
    }
    if (view === 'month') {
      const handleMonthDoubleClick = () => {
        // Set sensible start and end times based on current time
        const currentHour = moment().hour();
        // Weird logic avoids advancing the date by passing midnight
        const startHour = currentHour < 22 ? currentHour + 1 : 22;
        const endHour = startHour + 1;
        const startMoment = moment(props.startDate).startOf('day').hour(startHour);
        // Month view defaults end date to the next day, set it with the start date instead
        const endMoment = moment(props.startDate).startOf('day').hour(endHour);
        handleSelectRange(startMoment, endMoment);
      };
      return <MonthView.TimeTableCell {...props} onDoubleClick={handleMonthDoubleClick} />;
    }
    return null;
  };

  /* ---------------------------- */
  /* Page Render ---------------- */
  /* ---------------------------- */

  return (
    <StandardWrapper xl>
      <ThemeProvider theme={theme}>
        <Box paddingBottom={2}>
          <Typography variant="h1">Resource Scheduler</Typography>
        </Box>

        {/* Render Autocomplete component after resource options load */}
        {resourceLoading ? (
          <Box padding={2}>
            <h3>Loading Resource Selector</h3>
            <LinearProgress />
          </Box>
        ) : (
          <Box padding={2}>
            <Tooltip
              title="Select resources by clicking or typing the resource building, room, or name"
              placement="top-start"
            >
              <Autocomplete
                multiple
                options={resourceData?.resources}
                filterOptions={filterInput}
                getOptionLabel={(resource) => resource.resourceName}
                filterSelectedOptions
                onChange={(event, newValue) => handleResourceUpdate(newValue)}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip label={option.resourceName} color={getResourceColor(index)} {...getTagProps({ index })} />
                  ))
                }
                renderInput={(params) => (
                  <ClickAwayListener onClickAway={() => setResourceInput('')}>
                    <TextField
                      {...params}
                      onChange={(event) => setResourceInput(event.target.value)}
                      placeholder="Resource Search"
                    />
                  </ClickAwayListener>
                )}
                value={selectedResources}
              />
            </Tooltip>
          </Box>
        )}
        {!resourceLoading && reservationLoading && <LinearProgress />}
        <Dialog open={open} onClose={() => handleShowForm(false)}>
          <DialogContent>
            <ReservationForm
              // TODO tidy up props
              setOpen={handleShowForm}
              editingAppointment={editingAppointment}
              setEditingAppointment={setEditingAppointment}
              schedulerCategories={schedulerCategories}
              handleFormClose={() => handleShowForm(false)}
              activeReservations={getReservations()}
              addReservation={addReservation}
              updateReservation={updateReservation}
              updateOccurrence={updateOccurrence}
              cancelReservation={cancelReservation}
              isCancelling={isCancelling}
              setIsCancelling={setIsCancelling}
              onAdded={onAdded}
              onUpdated={onUpdated}
              onCancelled={onCancelled}
              addLoading={addLoading}
              updateLoading={updateLoading}
              updateOccLoading={updateOccLoading}
              cancelLoading={cancelLoading}
              submissionLoading={submissionLoading}
              setSubmissionLoading={setSubmissionLoading}
            />
          </DialogContent>
        </Dialog>

        {/* Calendar Component */}
        {!selectedResources.length ? null : (
          <Scheduler data={getReservations()} timeZone="America/Chicago">
            <ViewState />

            {selectedResources.length > 1 && groupingOn && <GroupingState groupOrientation={() => groupOrientation} />}

            {!isNarrowscreen.matches && (
              <WeekView
                startDayHour={calendarStart}
                endDayHour={calendarEnd}
                timeTableCellComponent={CustomTimeTableCellComponent('week')}
              />
            )}

            {!isNarrowscreen.matches && (
              <WeekView
                name="Work Week"
                displayName="Work Week"
                excludedDays={[0, 6]}
                startDayHour={calendarStart}
                endDayHour={calendarEnd}
                timeTableCellComponent={CustomTimeTableCellComponent('week')}
              />
            )}

            <DayView
              startDayHour={calendarStart}
              endDayHour={calendarEnd}
              timeTableCellComponent={CustomTimeTableCellComponent('day')}
            />
            {!isNarrowscreen.matches && <MonthView timeTableCellComponent={CustomTimeTableCellComponent('month')} />}

            <Appointments appointmentComponent={CustomAppointment} />
            {/* <CurrentTimeIndicator shadePreviousAppointments={true} shadePreviousCells={true} /> */}
            <Resources data={schedulerCategories} mainResourceName="resource" palette={resourcePalette} />

            {selectedResources.length > 1 && groupingOn && <IntegratedGrouping />}
            <AppointmentTooltip
              showCloseButton
              showOpenButton
              layoutComponent={CustomAppointmentTooltipLayout}
              showDeleteButton
              visible={tooltipClosed ? false : undefined}
            />

            <Toolbar rootComponent={CustomToolbar} />
            <DateNavigator />
            <ViewSwitcher />
            <TodayButton />

            {selectedResources.length > 1 && groupingOn && <GroupingPanel />}

            <CurrentTimeIndicator shadePreviousAppointments={true} shadePreviousCells={true} />
          </Scheduler>
        )}
        {/* Success message */}
        <Snackbar
          open={!!successMessage}
          autoHideDuration={5000}
          onClose={() => setSuccessMessage('')}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Alert onClose={() => setSuccessMessage('')} severity="success" variant="filled" sx={{ width: '100%' }}>
            {successMessage}
          </Alert>
        </Snackbar>

        {/* Error Alert */}
        <Dialog open={!!errMessage} onClose={() => setErrMessage(false)}>
          <DialogTitle>{'Error'}</DialogTitle>
          <DialogContent>
            <DialogContentText>{errMessage}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setErrMessage(false)} color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </StandardWrapper>
  );
};

export default ResourceScheduler;
