import React from 'react';
import { Container, Typography } from '@mui/material';

import StandardWrapper from '../components/StandardWrapper';

const NotAuthenticated = () => {
  return (
    <StandardWrapper>
      <Typography variant="h1">Not Authenticated</Typography>
      <Container>
        <Typography>
          Click <a href="/login">here</a> to sign in with your NetID.
        </Typography>
      </Container>
    </StandardWrapper>
  );
};

export default NotAuthenticated;
