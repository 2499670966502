import { useState, Fragment } from 'react';
import { useMutation } from '@apollo/client';

import moment from 'moment';

import { getStatusColor } from './utils'; // returns {backgroundColor: <status color>, color: <contrasting text color>}

import { REQUEST_ACT_RESOLUTION, MARK_RESOLVED, ADD_NOTE, CREATE_ERROR } from './graphql';
import { Button, Dialog, DialogContent, DialogTitle, Grid, Stack, TextField, Typography } from '@mui/material';

const VCRDetailsDialog = ({ vcr, user, refetch, handleCloseDetailsDialog }) => {
  const [createError] = useMutation(CREATE_ERROR);

  const [requestACTResolution] = useMutation(REQUEST_ACT_RESOLUTION, {
    variables: { userId: user._id },
    onCompleted: () => {
      setResolutionCallback();
      refetch();
    },
    onError: (e) => {
      createError({
        variables: {
          PVI: user.PVI,
          action: 'requestACTResolution',
          error: e ? JSON.stringify(e) : undefined,
          data: JSON.stringify({
            user,
          }),
        },
      });
    },
  });
  const [markResolved] = useMutation(MARK_RESOLVED, {
    variables: { userId: user._id },
    onCompleted: () => {
      setResolutionCallback();
      refetch();
    },
    onError: (e) => {
      createError({
        variables: {
          PVI: user.PVI,
          action: 'markResolved',
          error: e ? JSON.stringify(e) : undefined,
          data: JSON.stringify({
            user,
          }),
        },
      });
    },
  });

  const [addNote] = useMutation(ADD_NOTE, {
    variables: { submittedBy: user._id },
    onCompleted: () => {
      refetch();
    },
    onError: (e) => {
      createError({
        variables: {
          PVI: user.PVI,
          action: 'addNote',
          error: e ? JSON.stringify(e) : undefined,
          data: JSON.stringify({
            user,
          }),
        },
      });
    },
  });

  const handleResolution = (callback) => {
    setResolutionCallback(() => callback);
    setConfirmDialog(true);
  };

  const confirmResolution = () => {
    resolutionCallback();
    setConfirmDialog(false);
    handleCloseDetailsDialog();
  };
  const handleAddNote = (vcrId) => {
    const appendedNote = `Added a note: "${noteValue}"`;
    addNote({ variables: { text: appendedNote, vcrId } });
    setNoteValue('');
  };

  const [noteValue, setNoteValue] = useState('');
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [resolutionCallback, setResolutionCallback] = useState();

  if (!vcr) {
    return (
      <DialogContent>
        <Typography>
          The selected or linked report is not available. It may be out of date or invalid. Please contact
          <a href="mailto:help@brms.wisc.edu">help@brms.wisc.edu</a> for that report's data or if you feel you reached
          this message in error.
        </Typography>
      </DialogContent>
    );
  }
  return (
    <DialogContent>
      <Grid container spacing={1}>
        <Grid item xs={3} align="right">
          Status:
        </Grid>
        <Grid item xs={9} align="left" sx={{ ...getStatusColor(vcr?.status) }}>
          {vcr?.status}
        </Grid>

        <Grid item xs={3} align="right">
          Report #:
        </Grid>
        <Grid item xs={9} align="left">
          {vcr?.reportNumber}
        </Grid>

        <Grid item xs={3} align="right">
          Type :
        </Grid>
        <Grid item xs={9} align="left">
          {vcr?.type}
        </Grid>

        {vcr?.reason && (
          <>
            <Grid item xs={3} align="right">
              Reason:
            </Grid>

            <Grid item xs={9} align="left">
              {vcr?.reason}
            </Grid>
          </>
        )}

        <Grid item xs={3} align="right">
          Protocol:
        </Grid>
        <Grid item xs={9} align="left">
          {vcr?.protocolLabel}
        </Grid>

        <Grid item xs={3} align="right">
          Location:
        </Grid>
        <Grid item xs={9} align="left">
          {vcr?.location}
        </Grid>

        <Grid item xs={3} align="right">
          Additional description:
        </Grid>
        <Grid item xs={9} align="left">
          {vcr?.additionalDescription}
        </Grid>

        <Grid item xs={12} align="center">
          History:
        </Grid>
        {vcr?.history?.map((n, i) => (
          <Fragment key={i}>
            <Grid item xs={3} align="left">
              {moment(n.createdAt).format('MM-DD-YYYY h:mma')}
            </Grid>
            <Grid item xs={3} align="left">
              {n.submittedBy?.nameLastFirst}
            </Grid>
            <Grid item xs={6} align="left">
              {n.text}
            </Grid>
          </Fragment>
        ))}

        <Grid item xs={9}>
          <TextField
            value={noteValue}
            onChange={(e) => {
              setNoteValue(e.target.value);
            }}
            fullWidth
            multiline
            placeholder="Type note"
          />
        </Grid>
        <Grid item xs={3} sx={{ alignSelf: 'center' }}>
          <Button variant="outlined" onClick={() => handleAddNote(vcr?._id)} disabled={noteValue === ''}>
            Add
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12} padding="1em 0">
        <Stack gap=".5em">
          {vcr.status !== 'Awaiting ACT Resolution' && (
            <Button
              variant="contained"
              onClick={() => handleResolution(() => markResolved({ variables: { vcrId: vcr?._id, charged: false } }))}
              disabled={vcr?.statusPriority === 4}
            >
              Mark as Resolved
            </Button>
          )}
          {vcr.type === 'Overcrowded Cage' &&
            user.groups.filter((group) => ['admin', 'brms', 'brmsSuperuser'].includes(group)).length > 0 && (
              <Button
                variant="contained"
                onClick={() => handleResolution(() => markResolved({ variables: { vcrId: vcr?._id, charged: true } }))}
                disabled={vcr?.statusPriority === 4}
              >
                Mark as Resolved with charge
              </Button>
            )}
          {vcr.type === 'Overcrowded Cage' && vcr?.protocol?.members?.includes(user.PVI) && (
            <Button
              variant="contained"
              onClick={() => requestACTResolution({ variables: { vcrId: vcr?._id } })}
              disabled={vcr?.statusPriority === 3 || vcr?.statusPriority === 4}
            >
              Request ACT Resolution
            </Button>
          )}
          <Button variant="outlined" onClick={() => handleCloseDetailsDialog()}>
            Close
          </Button>
        </Stack>
      </Grid>
      <Dialog open={confirmDialog} onClose={() => setConfirmDialog(false)}>
        <DialogTitle>Confirm Resolution</DialogTitle>
        <DialogContent>
          <Typography paddingBottom="1em">
            {vcr.type === 'Overcrowded Cage' &&
              `I, ${user.firstName} ${user.lastName}, have separated or witnessed the separation of this cage.`}
            {vcr.type === 'Singly-Housed Animal' &&
              `I, ${user.firstName} ${user.lastName}, have added a filled out a singly-housed animal cage card or witnessed the resolution of this report.`}
          </Typography>
          <Button variant="contained" size="small" onClick={confirmResolution}>
            Confirm and Close
          </Button>
          <Button variant="outlined" size="small" onClick={() => setConfirmDialog(false)}>
            Cancel
          </Button>
        </DialogContent>
      </Dialog>
    </DialogContent>
  );
};

export default VCRDetailsDialog;
