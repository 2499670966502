import React, { useState } from 'react';

import StandardWrapper from '../components/StandardWrapper';

import LegacySARSearch from './LegacySARSearch';
import LegacyDARTable from './LegacyDARTable';

export default function LegacySARs({ user }) {
  const [searchOpen, setSearchOpen] = useState(true);
  const [onOrBefore, setOnOrBefore] = useState();
  const [onOrAfter, setOnOrAfter] = useState();
  const [protocolNumber, setProtocolNumber] = useState();
  const [building, setBuilding] = useState();
  const [initialized, setInitialized] = useState(false);
  const [emailAddress, setEmailAddress] = useState();

  const resetVars = () => {
    setOnOrAfter('');
    setOnOrBefore('');
    setProtocolNumber('');
    setBuilding('');
    setEmailAddress('');
  };

  const handleSubmit = (vars = {}) => {
    resetVars();
    console.log('vars: ', vars);
    setOnOrAfter(vars.onOrAfter);
    setOnOrBefore(vars.onOrBefore);
    setProtocolNumber(vars.protocolNumber);
    setBuilding(vars.building);
    setEmailAddress(vars.emailAddress);
    setInitialized(true);
    setSearchOpen(false);
  };

  return searchOpen || !initialized ? (
    <StandardWrapper>
      <LegacySARSearch
        user={user}
        title="DAR Search Terms"
        onSubmit={handleSubmit}
        onClose={initialized ? () => setSearchOpen(false) : undefined}
        // onOrBefore={onOrBefore}
        // onOrAfter={onOrAfter}
        // protocolNumber={protocolNumber}
        // building={building}
        // emailAddress={emailAddress}
      />
    </StandardWrapper>
  ) : (
    <StandardWrapper>
      <LegacyDARTable
        onFilter={() => setSearchOpen(true)}
        onOrAfter={onOrAfter}
        onOrBefore={onOrBefore}
        protocolNumber={protocolNumber}
        building={building}
        emailAddress={emailAddress}
      />
    </StandardWrapper>
  );
}
