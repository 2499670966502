import React, { useState } from 'react';
import {
  Table,
  Paper,
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  TextField,
  TableFooter,
  IconButton,
  Icon,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Tooltip,
} from '@mui/material';
import { sleep } from '../utils';
import HelpIcon from '@mui/icons-material/Help';

const setFocus = () => {
  document.getElementsByTagName('TABLE')[0].setAttribute('id', 'data-table');

  // Select table > body > last table row > first cell in last table row > textfield inside the cell
  let nextRow =
    document.getElementById('data-table').lastElementChild.firstElementChild.firstElementChild.firstElementChild
      .firstElementChild;

  nextRow.focus();
};

const AnimalTable = ({ sendInput, allRequired, val }) => {
  const [data, setData] = useState([]);

  const [newRow, setNewRow] = useState({
    id: 1,
  });

  const [clearKey, setClearKey] = useState(false);

  const [requirementDialog, setRequirementDialog] = useState(false);
  const [allRequiredDialog, setAllRequiredDialog] = useState(false);

  const appendNewRow = (key, value) => {
    let temp = newRow;
    temp[key] = value;
    setNewRow(temp);
  };

  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setRequirementDialog(false);
    setAllRequiredDialog(false);
  };

  return (
    <>
      <TableContainer className="datatable" component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">Animal ID</TableCell>
              <TableCell align="center">Name</TableCell>
              <TableCell align="center">Breed</TableCell>
              <TableCell align="center">Age</TableCell>
              <TableCell align="center">Markings</TableCell>
              <TableCell align="center">Sex</TableCell>
              <TableCell>
                <Tooltip title="Each row represents an animal that is being shipped. Press the plus icon to add more animals.">
                  <IconButton aria-label="info">
                    <HelpIcon color="primary" />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <TableRow className="datatable-filled-row" key={row.id}>
                <TableCell align="center">{row.animalID}</TableCell>
                <TableCell align="center">{row.name}</TableCell>
                <TableCell align="center">{row.breed ? row.breed : '\u2014'}</TableCell>
                <TableCell align="center">{row.age ? row.age : '\u2014'}</TableCell>
                <TableCell align="center">{row.markings ? row.markings : '\u2014'}</TableCell>
                <TableCell align="center">{row.sex}</TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  style={{
                    textAlign: 'end',
                  }}
                >
                  <IconButton
                    aria-label="delete"
                    onClick={({ id = row.id }) => {
                      let temp = data.filter((entry) => entry.id !== id);
                      setData(temp);

                      sendInput('animals', JSON.stringify(temp));
                    }}
                  >
                    <Icon color="primary">delete</Icon>
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell align="right">
                <TextField key={clearKey} onChange={(e) => appendNewRow('animalID', e.target.value)} />
              </TableCell>
              <TableCell align="right">
                <TextField key={clearKey} onChange={(e) => appendNewRow('name', e.target.value)} />
              </TableCell>
              <TableCell align="right">
                <TextField key={clearKey} onChange={(e) => appendNewRow('breed', e.target.value)} />
              </TableCell>
              <TableCell align="right">
                <TextField key={clearKey} onChange={(e) => appendNewRow('age', e.target.value)}></TextField>
              </TableCell>
              <TableCell align="right">
                <TextField
                  style={{ width: 200 }}
                  key={clearKey}
                  onChange={(e) => appendNewRow('markings', e.target.value)}
                />
              </TableCell>
              <TableCell align="right">
                <Select
                  key={clearKey}
                  onChange={(e) => {
                    appendNewRow('sex', e.target.value);
                  }}
                  defaultValue={''}
                >
                  <MenuItem value="Male">M</MenuItem>
                  <MenuItem value="Female">F</MenuItem>
                  <MenuItem value="Male (Castrated)">M/C</MenuItem>
                  <MenuItem value="Female (Spayed)">F/S</MenuItem>
                </Select>
              </TableCell>
              <TableCell
                style={{
                  textAlign: 'end',
                }}
              >
                <IconButton
                  aria-label="add"
                  onClick={() => {
                    if (newRow.sex === undefined || (newRow.animalID ? newRow.animalID.trim() === '' : true))
                      setRequirementDialog(true);
                    else if (allRequired && (!newRow.sex || !newRow.animalID || !newRow.age || !newRow.markings))
                      setAllRequiredDialog(true);
                    else {
                      let newData;
                      data.length === 0 ? (newData = [newRow]) : (newData = [...data, newRow]);
                      setData(newData);
                      setNewRow({ id: `${parseInt(newRow.id) + 1}` });
                      setClearKey(!clearKey);
                      sendInput('animals', JSON.stringify(newData));
                      sleep(200).then(setFocus);
                    }
                  }}
                >
                  <Icon color="secondary">add_circle</Icon>
                </IconButton>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <Dialog open={requirementDialog} onClose={handleToastClose}>
        <DialogTitle>{'Error'}</DialogTitle>
        <DialogContent>
          <DialogContentText>Animal ID and Sex are both required.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToastClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={allRequiredDialog} onClose={handleToastClose}>
        <DialogTitle>{'Error'}</DialogTitle>
        <DialogContent>
          <DialogContentText>All fields except Name are required.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToastClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <em>
        You must press the blue plus icon to add the animal. Filling out fields without pressing the plus icon will not
        submit that animal in the transfer request.
      </em>
    </>
  );
};

export default AnimalTable;
