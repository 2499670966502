import React, { useState } from 'react';

import { useParams } from 'react-router-dom';

import {
  Grid,
  Button,
  Stack,
  Card,
  CardHeader,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  Typography,
  LinearProgress,
  Alert,
  AlertTitle,
} from '@mui/material';

import StandardWrapper from '../components/StandardWrapper';
import { useQuery, useMutation } from '@apollo/client';

import { gql } from '@apollo/client';
import moment from 'moment';

import { KeyboardArrowLeft, Edit } from '@mui/icons-material';
import { buildingCodeToName } from '../utils';
import UpdateFastRequest from './UpdateFastRequestForm';

const GET_FAST_REQUEST = gql`
  query FastRequestByNumber($number: Int!) {
    fastRequestByNumber(number: $number) {
      _id
      protocolNumber
      submittedBy {
        firstName
        lastName
      }
      dateSubmitted
      number
      building
      animalId
      procedureDate
      fastStart
      fastEnd
      terminal
      procedure
      additionalDetails
      foodRemovedBy {
        firstName
        lastName
      }
      dateFoodRemoved
      returnedToCageBy {
        firstName
        lastName
      }
      dateReturnedToCage

      cancelledBy {
        firstName
        lastName
      }
      updatedBy {
        firstName
        lastName
      }
      completedBy {
        firstName
        lastName
      }
      dateUpdated
      dateCancelled
      dateCompleted
      foodProvidedWhenReturned
      sternalRecumbency
      permittedPVIs
      status
      PIName
    }
  }
`;

const CREATE_ERROR = gql`
  mutation CreateError($PVI: String, $action: String, $error: String, $data: String) {
    createError(PVI: $PVI, action: $action, error: $error, data: $data)
  }
`;

const REMOVE_FOOD = gql`
  mutation ($_id: ID!) {
    fastingRequestFoodRemoved(_id: $_id) {
      _id
      status
    }
  }
`;

const RETURN_ANIMAL = gql`
  mutation ($_id: ID!, $foodProvidedWhenReturned: Boolean!, $sternalRecumbency: Boolean!) {
    fastingRequestAnimalReturned(
      _id: $_id
      foodProvidedWhenReturned: $foodProvidedWhenReturned
      sternalRecumbency: $sternalRecumbency
    ) {
      _id
      status
    }
  }
`;

const COMPLETE_REQUEST = gql`
  mutation ($_id: ID!) {
    fastingRequestCompleted(_id: $_id) {
      _id
      status
    }
  }
`;

const CANCEL_REQUEST = gql`
  mutation ($_id: ID!) {
    cancelFastingRequest(_id: $_id) {
      _id
      status
    }
  }
`;

export default function FastRequest({ user, number: numberProp }) {
  const params = useParams();
  const [toast, setToast] = useState();
  const [errorDialog, setErrorDialog] = useState();
  const [loading, setLoading] = useState(false);
  const [returnOpen, setReturnOpen] = useState(false);
  const [foodProvided, setFoodProvided] = useState(false);
  const [recumbent, setRecumbent] = useState(false);
  const [fastPending, setFastPending] = useState(false);
  const [returnPending, setReturnPending] = useState(false);
  const [cancelPending, setCancelPending] = useState(false);
  const [completePending, setCompletePending] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [updating, setUpdating] = useState(false);
  const [reviewAcknowledged, setReviewAcknowledged] = useState(false);
  const [reviewing, setReviewing] = useState(false);
  const [key, setKey] = useState(false);

  const { number: numberParam } = params;
  const getNumber = () => {
    if (numberProp) {
      if (typeof parseInt(numberProp) === 'number') {
        return parseInt(numberProp);
      }
      console.error('invalid number passed as prop to FastRequest');
    }
    if (numberParam) {
      if (typeof parseInt(numberParam) === 'number') {
        return parseInt(numberParam);
      }
      console.error('invalid number passed as param to FastRequest');
    }
    throw new Error('No request order number passed to FastRequest component');
  };

  const {
    loading: requestLoading,
    error,
    data,
    refetch: _refetch,
  } = useQuery(GET_FAST_REQUEST, {
    variables: {
      number: getNumber(),
    },
  });

  const refetch = () => {
    setFastPending(false);
    setReturnPending(false);
    setCancelPending(false);
    setCompletePending(false);
    setKey(!key);
    _refetch();
  };

  // Checks to see if this is an error that can be handled gracefully
  // If so alerts the user and returns true, indicating that the error was handled and
  // an error does not need to be sent to the server/ticketing system
  const generateErrorMessage = (e) => {
    if (e) {
      if (e.message.includes('been cancelled')) {
        setErrorMessage('This request has already been cancelled.');
        return true;
      }
      if (e.message.includes('been returned')) {
        setErrorMessage('This animal has already been marked as returned.');
        return true;
      }
      if (e.message.includes('been fasted')) {
        setErrorMessage('A user has already indicated that the food was removed.');
        return true;
      }
    }

    return false;
  };

  const [createError] = useMutation(CREATE_ERROR);
  const [removeFood] = useMutation(REMOVE_FOOD, {
    onError(e) {
      refetch();
      console.log('e: ', e);
      setLoading(false);
      setToast(false);
      if (generateErrorMessage(e)) {
        return;
      }
      setErrorDialog(true);
      createError({
        variables: {
          PVI: user.PVI,
          action: 'fastingRequestFoodRemoved',
          error: e ? JSON.stringify(e) : undefined,
          data: JSON.stringify({
            user,
            data,
          }),
        },
      });
    },
    onCompleted() {
      if (!errorDialog) {
        setToast('Food removal recorded!');
        refetch();
      } else setToast(false);
    },
  });
  const [returnAnimal] = useMutation(RETURN_ANIMAL, {
    onError(e) {
      refetch();
      console.log('e: ', e);
      setLoading(false);
      setToast(false);
      if (generateErrorMessage(e)) {
        return;
      }
      setErrorDialog(true);
      createError({
        variables: {
          PVI: user.PVI,
          action: 'fastingRequestAnimalReturned',
          error: e ? JSON.stringify(e) : undefined,
          data: JSON.stringify({
            user,
            data,
          }),
        },
      });
    },
    onCompleted() {
      if (!errorDialog) {
        setToast('Animal return recorded!');
        refetch();
      } else setToast(false);
    },
  });
  const [cancelRequest] = useMutation(CANCEL_REQUEST, {
    onError(e) {
      refetch();
      console.log('e: ', e);
      setLoading(false);
      setToast(false);
      if (generateErrorMessage(e)) {
        return;
      }
      setErrorDialog(true);
      createError({
        variables: {
          PVI: user.PVI,
          action: 'fastingRequestCamcelled',
          error: e ? JSON.stringify(e) : undefined,
          data: JSON.stringify({
            user,
            data,
          }),
        },
      });
    },
    onCompleted() {
      if (!errorDialog) {
        setToast('Request cancelled!');
        refetch();
      } else setToast(false);
    },
  });
  const [completeRequest] = useMutation(COMPLETE_REQUEST, {
    onError(e) {
      refetch();
      console.log('e: ', e);
      setLoading(false);
      setToast(false);
      if (generateErrorMessage(e)) {
        return;
      }
      setErrorDialog(true);
      createError({
        variables: {
          PVI: user.PVI,
          action: 'fastingRequestCompleted',
          error: e ? JSON.stringify(e) : undefined,
          data: JSON.stringify({
            user,
            data,
          }),
        },
      });
    },
    onCompleted() {
      if (!errorDialog) {
        setToast('Request completed!');
        refetch();
      } else setToast(false);
    },
  });

  if (requestLoading) {
    return (
      <StandardWrapper>
        <LinearProgress />
      </StandardWrapper>
    );
  }

  if (error || (!loading && !data)) {
    console.log('error: ', error);

    return (
      <StandardWrapper>
        <Typography variant="h4">Could not display fasting request.</Typography>
      </StandardWrapper>
    );
  }

  console.log('data: ', data);
  const { fastRequestByNumber: fastRequest } = data;
  if (!fastRequest) {
    return (
      <StandardWrapper>
        <Typography variant="h4">Could not fasting request.</Typography>
      </StandardWrapper>
    );
  }

  const toBool = (string) => {
    if (typeof string !== 'string') throw new Error('Invalid type passed to toBool');
    return string.toLowerCase() === 'true';
  };

  const {
    _id,
    protocolNumber,
    submittedBy,
    dateSubmitted,
    number,
    building,
    animalId,
    procedureDate,
    fastStart,
    fastEnd,
    terminal,
    procedure,
    additionalDetails,
    foodRemovedBy,
    dateFoodRemoved,
    returnedToCageBy,
    dateReturnedToCage,
    cancelledBy,
    dateCancelled,
    foodProvidedWhenReturned,
    sternalRecumbency,
    permittedPVIs,
    PIName,
    status,
    dateUpdated,
    updatedBy,
    dateCompleted,
    completedBy,
  } = fastRequest;

  console.log('dateUpdated: ', dateUpdated);

  const formatDate = (date) => {
    if (!date) return null;
    return moment(date).format('MM/DD/YYYY h:mm a');
  };

  const getName = (_user) => {
    if (!_user) return null;
    const { firstName, lastName } = _user;
    if (firstName && lastName) {
      return `${firstName} ${lastName}`;
    }
    if (firstName) return firstName;
    if (lastName) return lastName;
    return null;
  };

  const capitalize = (s) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
  };
  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToast(false);
    setErrorDialog(false);
    setErrorMessage();
  };

  const isAfterProcedure = moment(procedureDate).unix() < moment().unix();

  const canRemoveFood =
    !fastPending &&
    !returnPending &&
    !cancelPending &&
    !completePending &&
    !dateFoodRemoved &&
    !dateCancelled &&
    !dateReturnedToCage &&
    !dateCompleted;
  const canReturnToCage =
    !returnPending &&
    !cancelPending &&
    !completePending &&
    !dateCancelled &&
    !dateReturnedToCage &&
    !(terminal && isAfterProcedure && dateFoodRemoved) &&
    !dateCompleted;
  const canComplete =
    !returnPending &&
    !cancelPending &&
    !completePending &&
    !dateCancelled &&
    !dateReturnedToCage &&
    !(terminal && isAfterProcedure && dateFoodRemoved) &&
    !dateCompleted;
  const canCancel =
    !returnPending &&
    !cancelPending &&
    !completePending &&
    !dateCancelled &&
    !dateReturnedToCage &&
    !dateCompleted &&
    !(terminal && isAfterProcedure && dateFoodRemoved);
  const canEdit = canRemoveFood && canReturnToCage && canCancel;

  const handleRemoveFood = () => {
    if (canRemoveFood) {
      if (dateUpdated) {
        setReviewing(true);
      } else {
        removeFood({ variables: { _id } });
        setFastPending(true);
      }
    } else {
      setErrorDialog(true);
    }
  };

  const handleReviewedSubmit = () => {
    if (canRemoveFood && reviewAcknowledged) {
      removeFood({ variables: { _id } });
      setFastPending(true);
      setReviewing(false);
    }
  };
  const handleReturn = () => {
    if (canReturnToCage) {
      setReturnOpen(true);
    } else {
      setErrorDialog(true);
    }
  };
  const handleCancel = () => {
    if (canCancel) {
      cancelRequest({ variables: { _id } });
      setCancelPending();
    } else {
      setErrorDialog(true);
    }
  };
  const handleComplete = () => {
    if (canCancel) {
      completeRequest({ variables: { _id } });
      setCompletePending();
    } else {
      setErrorDialog(true);
    }
  };

  const returnColor = '#488948';
  const cancelColor = '#dadfe1';
  const overdueColor = '#c5050b';
  const inProgressColor = '#006a8a';

  const getColor = () => {
    if (status === 'returned' || status === 'completed') {
      return returnColor;
    }
    if (status === 'cancelled') {
      return cancelColor;
    }
    if (status === 'overdue' || status === 'returnOverdue') {
      return overdueColor;
    }
    return inProgressColor;
  };

  const formatStatus = (status) => {
    if (status === 'awaitingReturn') {
      return 'Awaiting Return';
    }
    if (status === 'returnOverdue') {
      return 'Return Overdue';
    }
    if (status === 'overdue') {
      return 'Fasting Overdue';
    }

    return capitalize(status);
  };

  if (updating) {
    return (
      <UpdateFastRequest
        {...fastRequest}
        onSubmit={() => {
          setUpdating(false);
          refetch();
        }}
        onClose={() => {
          setUpdating(false);
        }}
        user={user}
      />
    );
  }
  return (
    <StandardWrapper>
      <Grid container justifyContent="space-between">
        <Grid item>
          <IconButton aria-label="back to fast requests" href="/fast-requests">
            <KeyboardArrowLeft />
          </IconButton>
        </Grid>
        {canEdit && (
          <Grid item>
            <IconButton
              aria-label="edit fast request"
              onClick={() => {
                setUpdating(true);
              }}
            >
              <Edit />
            </IconButton>
          </Grid>
        )}
      </Grid>
      <Stack spacing={2} justifyContent="left" width="100%" paddingInline="1em">
        <div>
          <Card variant="outlined" raised sx={{ border: `2px solid ${getColor()}`, maxWidth: '20em', margin: 'auto' }}>
            <CardHeader title={formatStatus(status)} />
          </Card>
        </div>

        {['submitted', 'overdue'].includes(status) && (
          <DataLine label="Start Fast" value={formatDate(fastStart)} align="center" />
          // <Typography style={{ textAlign: 'center' }}>
          //   <span style={{ fontWeight: 'bold' }}>Start Fast:</span>&nbsp;{formatDate(fastStart)}
          // </Typography>
        )}
        <div>
          <DataLine label="PI" value={PIName} />
          <DataLine label="Building" value={buildingCodeToName(building)} />
          <DataLine label="Animal ID" value={animalId} />
          {!['submitted', 'overdue'].includes(status) && <DataLine label="Start Fast" value={formatDate(fastStart)} />}
          <DataLine label="End Fast" value={terminal ? 'This procedure is terminal' : formatDate(fastEnd)} />
        </div>

        <div>
          <DataLine label="Submitted By" value={getName(submittedBy)} />
          <DataLine label="Date Submitted" value={formatDate(dateSubmitted)} />
          <DataLine label="Protocol Number" value={protocolNumber} />
          <DataLine label="Terminal" value={terminal ? 'Yes' : 'No'} />
          <DataLine label="Procedure" value={procedure} />
          <DataLine label="Procedure Date" value={formatDate(procedureDate)} />
          <DataLine label="Additional Details" value={additionalDetails} />
        </div>

        {dateFoodRemoved && (
          <div>
            <DataLine label="Date Food Removed" value={formatDate(dateFoodRemoved)} />
            <DataLine label="Food Removed By" value={getName(foodRemovedBy)} />
          </div>
        )}

        {dateReturnedToCage && (
          <div>
            <DataLine label="Date Returned" value={formatDate(dateReturnedToCage)} />
            <DataLine label="Returned By" value={getName(returnedToCageBy)} />
            <DataLine label="Food Provided" value={foodProvidedWhenReturned ? 'Yes' : 'No'} />
            <DataLine label="Sternal Recumbency" value={sternalRecumbency ? 'Yes' : 'No'} />
          </div>
        )}

        {dateCancelled && (
          <div>
            <DataLine label="Date Cancelled" value={formatDate(dateCancelled)} />
            <DataLine label="Cancelled By" value={getName(cancelledBy)} />
          </div>
        )}

        {dateCompleted && (
          <div>
            <DataLine label="Date Completed" value={formatDate(dateCompleted)} />
            <DataLine label="Completed By" value={getName(completedBy)} />
          </div>
        )}
        {dateUpdated && (
          <div>
            <DataLine label="Date Edited" value={formatDate(dateUpdated)} />
            <DataLine label="Edited By" value={getName(updatedBy)} />
          </div>
        )}
        <Grid container justifyContent="space-around">
          {canRemoveFood && (
            <Grid item xs={12} sm={3}>
              <Button
                style={{
                  backgroundColor: inProgressColor,
                }}
                fullWidth
                variant="contained"
                onClick={handleRemoveFood}
                disabled={loading}
              >
                Remove Food
              </Button>
            </Grid>
          )}
          {!canRemoveFood && canComplete && (
            <Grid item xs={12} sm={3}>
              <Button
                style={{ backgroundColor: returnColor }}
                variant="contained"
                fullWidth
                onClick={handleComplete}
                disabled={loading}
              >
                Not Returned
              </Button>
            </Grid>
          )}

          {canReturnToCage && (
            <Grid item xs={12} sm={3}>
              <Button
                style={{ backgroundColor: returnColor }}
                variant="contained"
                fullWidth
                onClick={handleReturn}
                disabled={loading}
              >
                Return & Feed
              </Button>
            </Grid>
          )}

          {canCancel && (
            <Grid item xs={12} sm={3}>
              <Button fullWidth variant="contained" onClick={handleCancel} disabled={loading}>
                Cancel Request
              </Button>
            </Grid>
          )}
        </Grid>
      </Stack>
      {/* <div style={{ marginBottom: 30 }}>
        <Typography variant="h4">Request&nbsp;#{number}</Typography>
        <div
          style={{
            padding: 10,
            width: 175,
            margin: '15px auto',
            borderWidth: 2,
            borderStyle: 'solid',
            borderColor: getColor(),
            borderRadius: 10,
          }}
        >
          <Typography variant="h6">&nbsp;{formatStatus(status)}</Typography>
        </div>
        {['submitted', 'overdue'].includes(status) && (
          <Typography style={{ textAlign: 'center', marginBottom: 15 }} variant="h6">
            <span style={{ fontWeight: 'bold' }}>Start Fast:</span>&nbsp;{formatDate(fastStart)}
          </Typography>
        )}
        <Typography style={{ textAlign: 'left' }}>
          <span style={{ fontWeight: 'bold' }}>PI:</span>&nbsp;{PIName}
        </Typography>
        <Typography style={{ textAlign: 'left' }}>
          <span style={{ fontWeight: 'bold' }}>Building:</span>&nbsp;{buildingCodeToName(building) || building}
        </Typography>
        <Typography style={{ textAlign: 'left' }}>
          <span style={{ fontWeight: 'bold' }}>Animal ID:</span>&nbsp;{animalId}
        </Typography>
        {!['submitted', 'overdue'].includes(status) && (
          <Typography style={{ textAlign: 'left' }}>
            <span style={{ fontWeight: 'bold' }}>Start Fast:</span>&nbsp;{formatDate(fastStart)}
          </Typography>
        )}
        <Typography style={{ textAlign: 'left' }}>
          <span style={{ fontWeight: 'bold' }}>End Fast:</span>&nbsp;
          {terminal ? 'This procedure is terminal' : formatDate(fastEnd)}
        </Typography>
      </div>
      <div style={{ marginBottom: 30 }}>
        <Typography style={{ textAlign: 'left' }}>
          <span style={{ fontWeight: 'bold' }}>Submitted By:</span>&nbsp;{getName(submittedBy)}
        </Typography>
        <Typography style={{ textAlign: 'left' }}>
          <span style={{ fontWeight: 'bold' }}>Date Submitted:</span>&nbsp;{formatDate(dateSubmitted)}
        </Typography>
        <Typography style={{ textAlign: 'left' }}>
          <span style={{ fontWeight: 'bold' }}>Protocol Number:</span>&nbsp;{protocolNumber}
        </Typography>
        <Typography style={{ textAlign: 'left' }}>
          <span style={{ fontWeight: 'bold' }}>Terminal:</span>&nbsp;{terminal ? 'Yes' : 'No'}
        </Typography>
        <Typography style={{ textAlign: 'left' }}>
          <span style={{ fontWeight: 'bold' }}>Procedure:</span>&nbsp;{procedure}
        </Typography>
        <Typography style={{ textAlign: 'left' }}>
          <span style={{ fontWeight: 'bold' }}>Procedure Date:</span>&nbsp;{formatDate(procedureDate)}
        </Typography>
        <Typography style={{ textAlign: 'left' }}>
          <span style={{ fontWeight: 'bold' }}>Additional Details:</span>&nbsp;{additionalDetails}
        </Typography>
      </div>

      {dateFoodRemoved && (
        <div style={{ marginBottom: 30 }}>
          <Typography style={{ textAlign: 'left' }}>
            <span style={{ fontWeight: 'bold' }}>Date Food Removed:</span>&nbsp;{formatDate(dateFoodRemoved)}
          </Typography>
          <Typography style={{ textAlign: 'left' }}>
            <span style={{ fontWeight: 'bold' }}>Food Removed By:</span>&nbsp;{getName(foodRemovedBy)}
          </Typography>
        </div>
      )}
      {dateReturnedToCage && (
        <div style={{ marginBottom: 30 }}>
          <Typography style={{ textAlign: 'left' }}>
            <span style={{ fontWeight: 'bold' }}>Date Returned:</span>&nbsp;{formatDate(dateReturnedToCage)}
          </Typography>
          <Typography style={{ textAlign: 'left' }}>
            <span style={{ fontWeight: 'bold' }}>Returned By:</span>&nbsp;{getName(returnedToCageBy)}
          </Typography>
          <Typography style={{ textAlign: 'left' }}>
            <span style={{ fontWeight: 'bold' }}>Food Provided:</span>&nbsp;{foodProvidedWhenReturned ? 'Yes' : 'No'}
          </Typography>
          <Typography style={{ textAlign: 'left' }}>
            <span style={{ fontWeight: 'bold' }}>Sternal Recumbency:</span>&nbsp;{sternalRecumbency ? 'Yes' : 'No'}
          </Typography>
        </div>
      )}
      {dateCancelled && (
        <div style={{ marginBottom: 30 }}>
          <Typography style={{ textAlign: 'left' }}>
            <span style={{ fontWeight: 'bold' }}>Date Cancelled:</span>&nbsp;{formatDate(dateCancelled)}
          </Typography>
          <Typography style={{ textAlign: 'left' }}>
            <span style={{ fontWeight: 'bold' }}>Cancelled By:</span>&nbsp;{getName(cancelledBy)}
          </Typography>
        </div>
      )}
      {dateCompleted && (
        <div style={{ marginBottom: 30 }}>
          <Typography style={{ textAlign: 'left' }}>
            <span style={{ fontWeight: 'bold' }}>Date Completed:</span>&nbsp;{formatDate(dateCompleted)}
          </Typography>
          <Typography style={{ textAlign: 'left' }}>
            <span style={{ fontWeight: 'bold' }}>Completed By:</span>&nbsp;{getName(completedBy)}
          </Typography>
        </div>
      )}
      {dateUpdated && (
        <div style={{ marginBottom: 30 }}>
          <Typography style={{ textAlign: 'left' }}>
            <span style={{ fontWeight: 'bold' }}>Date Edited:</span>&nbsp;{formatDate(dateUpdated)}
          </Typography>
          <Typography style={{ textAlign: 'left' }}>
            <span style={{ fontWeight: 'bold' }}>Edited By:</span>&nbsp;{getName(updatedBy)}
          </Typography>
        </div>
      )}
      <Grid container justifyContent="space-between" style={{ marginTop: 16 }}>
        {canRemoveFood && (
          <Hidden xsDown>
            <Grid item xs={12} sm={4}>
              <Button
                style={{ float: 'right', borderColor: inProgressColor, borderWidth: 2 }}
                variant="outlined"
                // color="primary"
                onClick={handleRemoveFood}
                disabled={loading}
              >
                Remove Food
              </Button>
            </Grid>
          </Hidden>
        )}
        {canRemoveFood && (
          <Hidden smUp>
            <Grid item xs={12}>
              <Button
                style={{ borderColor: inProgressColor, width: '100%', margin: '10px 0', height: 50, borderWidth: 2 }}
                variant="outlined"
                onClick={handleRemoveFood}
                disabled={loading}
              >
                Remove Food
              </Button>
            </Grid>
          </Hidden>
        )}

        {!canRemoveFood && (
          <Hidden xsDown>
            {canComplete ? (
              <Grid item xs={12} sm={4}>
                <Button
                  style={{ float: 'right', borderColor: returnColor, borderWidth: 2 }}
                  variant="outlined"
                  // color="primary"
                  onClick={handleComplete}
                  disabled={loading}
                >
                  Not Returned
                </Button>
              </Grid>
            ) : (
              <Grid item xs={4} />
            )}
          </Hidden>
        )}
        {!canRemoveFood && (
          <Hidden smUp>
            {canComplete && (
              <Grid item xs={12}>
                <Button
                  style={{ borderColor: returnColor, width: '100%', margin: '10px 0', height: 50, borderWidth: 2 }}
                  variant="outlined"
                  onClick={handleComplete}
                  disabled={loading}
                >
                  Not Returned
                </Button>
              </Grid>
            )}
          </Hidden>
        )}

        <Hidden xsDown>
          {canReturnToCage ? (
            <Grid item xs={12} sm={4}>
              <Button
                style={{ borderColor: returnColor, borderWidth: 2 }}
                variant="outlined"
                // color="primary"
                onClick={handleReturn}
                disabled={loading}
              >
                {'Return & Feed'}
              </Button>
            </Grid>
          ) : (
            <Grid item xs={4} />
          )}
        </Hidden>
        <Hidden smUp>
          {canReturnToCage && (
            <Grid item xs={12}>
              <Button
                style={{ borderColor: returnColor, borderWidth: 2, width: '100%', margin: '10px 0', height: 50 }}
                variant="outlined"
                onClick={handleReturn}
                disabled={loading}
              >
                {'Return & Feed'}
              </Button>
            </Grid>
          )}
        </Hidden>

        <Hidden xsDown>
          {canCancel ? (
            <Grid item sm={4}>
              <Button
                style={{ float: 'left', borderWidth: 2 }}
                variant="outlined"
                // color="primary"
                onClick={handleCancel}
                disabled={loading}
              >
                Cancel Request
              </Button>
            </Grid>
          ) : (
            <Grid item xs={4} />
          )}
        </Hidden>

        <Hidden smUp>
          {canCancel && (
            <Grid item xs={12}>
              <Button
                style={{ width: '100%', margin: '10px 0', height: 50, borderWidth: 2 }}
                variant="outlined"
                // color="primary"
                onClick={handleCancel}
                disabled={loading}
              >
                Cancel Request
              </Button>
            </Grid>
          )}
        </Hidden>
      </Grid> */}

      <Dialog
        open={returnOpen}
        onClose={() => {
          setReturnOpen(false);
        }}
      >
        <DialogTitle>Return and Feed Animal</DialogTitle>
        <DialogContent>
          <div>
            <FormControl component="fieldset">
              <FormLabel component="legend">Food Provided?</FormLabel>
              <RadioGroup
                aria-label="food-provided"
                name="foodProvided"
                value={foodProvided}
                onChange={(e) => setFoodProvided(toBool(e.target.value))}
              >
                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                <FormControlLabel value={false} control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </div>
          <div>
            <FormControl component="fieldset">
              <FormLabel component="legend">Sternal Recumbency?</FormLabel>
              <RadioGroup
                aria-label="sternal-recumbency"
                name="sternalRecumbency"
                value={recumbent}
                onChange={(e) => setRecumbent(toBool(e.target.value))}
              >
                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                <FormControlLabel value={false} control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </div>
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent="space-between">
            <Button
              onClick={() => {
                setReturnOpen(false);
              }}
              variant="outlined"
            >
              Close
            </Button>
            <Button
              onClick={() => {
                setReturnOpen(false);
                setReturnPending(true);
                returnAnimal({
                  variables: { _id, sternalRecumbency: recumbent, foodProvidedWhenReturned: foodProvided },
                });
              }}
              style={{ borderColor: returnColor, borderWidth: 2 }}
              variant="outlined"
            >
              {'Return & Feed'}
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>

      <Dialog
        open={reviewing}
        onClose={() => {
          setReviewing(false);
        }}
      >
        <DialogTitle>Please Review Updated Request Details</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This request has been edited after it was originally submitted. Please review the details of the request
            carefully to ensure you are following the most recent instructions.
          </DialogContentText>
          <div>
            <FormControlLabel
              className="checkbox"
              control={
                <Checkbox
                  key={key}
                  checked={reviewAcknowledged}
                  onChange={(e) => {
                    setReviewAcknowledged(e.target.checked);
                  }}
                ></Checkbox>
              }
              label="I acknowledge that I have thuroughly reviewed the new details of this request."
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent="space-between">
            <Button
              onClick={() => {
                setReviewing(false);
              }}
              variant="outlined"
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                handleReviewedSubmit();
              }}
              style={canRemoveFood && reviewAcknowledged ? { borderColor: inProgressColor, borderWidth: 2 } : {}}
              variant="outlined"
              disabled={!canRemoveFood || !reviewAcknowledged}
            >
              Submit
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>

      <Snackbar open={toast} autoHideDuration={6000} onClose={handleToastClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
        <Alert onClose={handleToastClose} variant="filled" severity="success">
          <AlertTitle>Success</AlertTitle>
          {toast}
        </Alert>
      </Snackbar>
      <Dialog open={errorDialog} onClose={handleToastClose}>
        <DialogTitle>{'Error'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Failed to update fasting request. BRMS IT has been alerted of the issue.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToastClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={errorMessage}
        onClose={() => {
          handleToastClose();
          refetch();
        }}
      >
        <DialogTitle>{'Error'}</DialogTitle>
        <DialogContent>
          <DialogContentText>{errorMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleToastClose();
              refetch();
            }}
            color="primary"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </StandardWrapper>
  );
}
function DataLine({ label, value, align }) {
  label = label || '';
  value = value || '';
  return (
    <Typography sx={{ textAlign: align || 'left' }}>
      <span style={{ fontWeight: 'bold' }}>{`${label}: `}</span>
      {value}
    </Typography>
  );
}
