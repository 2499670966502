import { gql } from '@apollo/client';

export const GET_USERS = gql`
  {
    users {
      PVI
      firstName
      lastName
      emailAddress
      groups
    }
  }
`;
export const CREATE_ERROR = gql`
  mutation CreateError($PVI: String, $action: String, $error: String, $data: String) {
    createError(PVI: $PVI, action: $action, error: $error, data: $data)
  }
`;

export const GET_TOASTS = gql`
  query GetToasts($form: String) {
    toasts(form: $form) {
      _id
      message
      forms
    }
  }
`;
