import React, { useState } from 'react';
import {
  Button,
  Grid,
  TextField,
  CircularProgress,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Alert,
  AlertTitle,
  Stack,
  Box,
  Typography,
} from '@mui/material';
import { gql } from '@apollo/client';
import { useMutation } from '@apollo/client';

import StandardWrapper from '../components/StandardWrapper';

const EditInventoryItem = ({
  user,
  onSubmit,
  onClose,
  inventoryItem: { _id, name: _name, unit: _unit, vendor: _vendor, code: _code, description: _description },
}) => {
  const [name, setName] = useState(_name);
  const [unit, setUnit] = useState(_unit);
  const [vendor, setVendor] = useState(_vendor);
  const [code, setCode] = useState(_code);
  const [description, setDescription] = useState(_description);
  const [toast, setToast] = useState();
  const [errorDialog, setErrorDialog] = useState();
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setToast(false);
    setErrorDialog(false);
  };

  const resetForm = () => {
    setName('');
    setUnit('');
    setVendor('');
    setCode('');
    setDescription('');
  };

  const CREATE_ERROR = gql`
    mutation CreateError($PVI: String, $action: String, $error: String, $data: String) {
      createError(PVI: $PVI, action: $action, error: $error, data: $data)
    }
  `;
  const [createError] = useMutation(CREATE_ERROR);

  const EDIT_INVENTORY_ITEM = gql`
    mutation EditInventoryItem(
      $_id: String!
      $name: String!
      $unit: String!
      $vendor: String!
      $code: String
      $description: String
    ) {
      updateInventoryItem(
        _id: $_id
        name: $name
        unit: $unit
        vendor: $vendor
        code: $code
        description: $description
      ) {
        _id
      }
    }
  `;

  const getVariables = () => {
    return {
      _id,
      name,
      unit,
      vendor,
      code,
      description,
    };
  };
  const [updateInventoryItem] = useMutation(EDIT_INVENTORY_ITEM, {
    onError(e) {
      setLoading(false);
      setErrorDialog(true);
      setToast(false);
      createError({
        variables: {
          PVI: user.PVI,
          action: 'updateInventoryItem',
          error: e ? JSON.stringify(e) : undefined,
          data: JSON.stringify({
            user,
            vars: getVariables(),
          }),
        },
      });
    },
    onCompleted() {
      if (!errorDialog) {
        setLoading(false);
        setToast(true);
        resetForm();
        onSubmit();
        setSubmitting(false);
      } else setToast(false);
    },
  });

  const handleSubmit = () => {
    const variables = getVariables();
    console.log('variables: ', variables);
    updateInventoryItem({ variables });
  };
  return submitting ? (
    <CircularProgress />
  ) : (
    <StandardWrapper>
      <Typography variant="h1">Edit Inventory Item</Typography>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          setLoading(true);
          // sleep(500);
          handleSubmit();
        }}
      >
        <Stack spacing="1em" maxWidth="20em" margin="auto">
          <TextField
            variant="outlined"
            fullWidth
            required
            label="Name"
            placeholder="E.g. Large Nitrile Gloves"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />

          <TextField
            variant="outlined"
            fullWidth
            required
            label="Unit"
            placeholder="E.g. 24 boxes"
            value={unit}
            onChange={(e) => {
              setUnit(e.target.value);
            }}
          />

          <TextField
            variant="outlined"
            fullWidth
            required
            label="Vendor"
            placeholder="E.g. Verona Safety"
            value={vendor}
            onChange={(e) => {
              setVendor(e.target.value);
            }}
          />

          <TextField
            variant="outlined"
            fullWidth
            label="Item No."
            placeholder="Optional ID number"
            value={code}
            onChange={(e) => {
              setCode(e.target.value);
            }}
          />

          <TextField
            variant="outlined"
            label="Description"
            placeholder="Optional additional description"
            multiline
            fullWidth
            minRows={3}
            maxRows={6}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          ></TextField>

          <Box width="100%" justifyContent="space-between" display="flex">
            {onClose && (
              <Button style={{ width: 125 }} variant="outlined" onClick={onClose}>
                Close
              </Button>
            )}
            <Button style={{ width: 125 }} variant="contained" color="primary" type="submit" disabled={loading}>
              Submit
              {loading && (
                <CircularProgress disableShrink style={{ position: 'absolute', zIndex: 2, color: 'white' }} size={24} />
              )}
            </Button>
          </Box>
        </Stack>
      </form>
      <Snackbar open={toast} autoHideDuration={6000} onClose={handleToastClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
        <Alert onClose={handleToastClose} variant="filled" severity="success">
          <AlertTitle>Success</AlertTitle>
          Inventory item has been updated!
        </Alert>
      </Snackbar>
      <Dialog open={errorDialog} onClose={handleToastClose}>
        <DialogTitle>{'Error'}</DialogTitle>
        <DialogContent>
          <DialogContentText>Failed to update item</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToastClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </StandardWrapper>
  );
};

export default EditInventoryItem;
