import moment from 'moment'
import _ from 'lodash';
import { rrulestr, RRule } from 'rrule';

export const validateRRule = (_rRule) => {
    if (!_rRule || _.isEmpty(_rRule)) {
        return false
    }
    if (!_rRule.dtstart) {
        console.log("No dtstart in rRule")
        return false;
    }
    if (!moment(_rRule.dtstart).isValid()) {
        console.log("Invalid dtstart in rRule")
        return false;
    }
    if (!_rRule.until) {
        console.log("No until date in rRule")
        return false;
    }
    if (!moment(_rRule.until).isValid()) {
        console.log("Invalid until date in rRule")
        return false;
    }
    return true
}

export const parseRRuleString = str => {
    if (typeof str !== 'string' || str.length < 1) {
        throw new Error(`Invalid rRule String: ${str}`)
    }
    try {
        const rule = rrulestr(str)
        const { options: { dtstart, until } } = rule;

        const parsedRule = {
            ...rule.origOptions,
            dtstart: new Date((dtstart)),
            until: new Date((until))
        }
        if (validateRRule(parsedRule)) {
            return parsedRule;
        } else {
            throw Error("Parsed rRule was invalid")
        }
    } catch (e) {
        console.error("Error parsing rRule string: ", str)
    }
}

export const getEODMoment = x => {
    if (!x || !moment(x).isValid()) {
        console.error("Invalid moment ", x)
        return x
    }
    const res = moment(x)
    res.hour(23).minute(59).second(59)
    return res;
}

export const getMaxDate = () => {
    const x = moment().add(6, 'months')
    return getEODMoment(x)
}

export const formatEventForLog = (event, verbose = false) => {
    let str = "\n"
    str += `  Start: ${moment(event.startDate).format("dd M/D/YYYY hh:mm a")}\n`
    str += `  End: ${moment(event.endDate).format("dd M/D/YYYY hh:mm a")}\n`
    if (verbose) {
        str += `  Event ID: ${event.id}\n`
        str += `  Resource ID: ${event.resource}\n`
        str += `  Is series: ${!!event.rRule}\n`
        str += `  Series ID: ${event.resource}\n`
        if (!!event.rRule) {
            str += `  rRule is valid: ${validateRRule(event.rRule)}\n`
        }
        if (validateRRule(event.rRule)) {
            str += `  Repeats until: ${moment(event.rRule.until).format("dd M/D/YYYY hh:mm a")}\n`
            str += `  exDate: ${event.exDate}\n`
        }
    }

    return str
}

