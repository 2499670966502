import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client';
import { LEGACY_DAR_QUERY } from './graphql';

import { CSVLink, CSVDownload } from 'react-csv';
import moment from 'moment';
import { isAuthError } from '../utils';

import {
  LinearProgress,
  Typography,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
} from '@mui/material';

import { Error } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import RefreshIcon from '@mui/icons-material/Refresh';
import DownloadIcon from '@mui/icons-material/SaveAlt';

const LegacyDARTable = ({ protocolNumber, building, onOrAfter, onOrBefore, emailAddress, onFilter }) => {
  const {
    loading,
    error,
    data = { legacyDARs: [] },
    refetch,
  } = useQuery(LEGACY_DAR_QUERY, {
    variables: {
      protocolNumber,
      building,
      onOrAfter,
      onOrBefore,
      emailAddress,
    },
  });
  let rows = [...data.legacyDARs]
    .sort((x, y) => moment(y.dateSubmitted).unix() - moment(x.dateSubmitted).unix())
    .map((x) => {
      const y = { ...x };
      delete y.__typename;
      return y;
    });

  let filename = 'DAR';
  if (protocolNumber) filename += `_${protocolNumber}`;
  if (building) filename += `_${building}`;
  if (onOrAfter) filename += `_from_${moment(onOrAfter).format('MM-DD-YYYY')}`;
  if (onOrBefore) filename += `_through_${moment(onOrBefore).format('MM-DD-YYYY')}`;
  filename += '.csv';

  if ((loading || !data) && error) {
    if (isAuthError(error)) {
      window.location.href = '/login';
    }
    console.error('Error fetching users in LegacyDARTable.');
    console.error(error);
  }

  if (error) {
    console.log('error: ', error);

    return <Error />;
  }

  const renderDateLabels = () => {
    if (onOrAfter && onOrBefore)
      return (
        <Typography style={{ display: 'inline', float: 'left' }}>
          <span style={{ fontWeight: 'bold' }}>Date Submitted:</span>&nbsp;From {moment(onOrAfter).format('YYYY-MM-DD')}{' '}
          through {moment(onOrBefore).format('YYY-MM-DD')}
        </Typography>
      );
    else if (onOrAfter)
      return (
        <Typography style={{ display: 'inline', float: 'left' }}>
          <span style={{ fontWeight: 'bold' }}>Date Submitted:</span>&nbsp;On or after{' '}
          {moment(onOrAfter).format('YYYY-MM-DD')}
        </Typography>
      );
    else if (onOrBefore)
      return (
        <Typography style={{ display: 'inline', float: 'left' }}>
          <span style={{ fontWeight: 'bold' }}>Date Submitted:</span>&nbsp;On or before{' '}
          {moment(onOrBefore).format('YYYY-MM-DD')}
        </Typography>
      );
    return null;
  };

  return loading || !rows ? (
    <LinearProgress />
  ) : (
    <div>
      <Typography variant="h1">DAR Query Results</Typography>
      <Grid container justifyContent="space-between">
        <Grid item xs={8}>
          {protocolNumber && (
            <Typography style={{ textAlign: 'left' }}>
              <span style={{ fontWeight: 'bold' }}>Protocol:</span>&nbsp;{protocolNumber}
            </Typography>
          )}
          {building && (
            <Typography style={{ textAlign: 'left' }}>
              <span style={{ fontWeight: 'bold' }}>Building:</span>&nbsp;{building}
            </Typography>
          )}
          {renderDateLabels()}
          <div style={{ marginBottom: 10 }} />
        </Grid>
        <Grid item xs={4}>
          {onFilter && (
            <IconButton aria-label="update-query" onClick={onFilter} style={{ float: 'right' }}>
              <SearchIcon fontSize="large" />
            </IconButton>
          )}
          <IconButton aria-label="refresh-results" onClick={() => refetch()} style={{ float: 'right' }}>
            <RefreshIcon fontSize="large" />
          </IconButton>
          <CSVLink data={rows} target="_blank" filename={filename}>
            <IconButton aria-label="download-csv" onClick={console.log} style={{ float: 'right' }}>
              <DownloadIcon fontSize="large" />
            </IconButton>
          </CSVLink>
        </Grid>
      </Grid>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Date Submitted</TableCell>
              <TableCell align="right">Protocol Number</TableCell>
              <TableCell align="right">Building</TableCell>
              <TableCell align="right">Room</TableCell>
              <TableCell align="right">Rack</TableCell>
              <TableCell align="right"># Dead</TableCell>
              <TableCell align="right"># Total</TableCell>
              <TableCell align="right">Comments</TableCell>
              <TableCell align="right">Species</TableCell>
              <TableCell align="right">Age</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(
              ({
                id,
                dateSubmitted,
                protocolNumber,
                building,
                room,
                rack,
                numDead,
                numTotal,
                age,
                comments,
                species,
              }) => (
                <TableRow key={id}>
                  <TableCell component="th" scope="row">
                    {moment(dateSubmitted).format('YYYY-MM-DD hh:mm a')}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {protocolNumber}
                  </TableCell>
                  <TableCell align="right">{building}</TableCell>
                  <TableCell align="right">{room}</TableCell>
                  <TableCell align="right">{rack}</TableCell>
                  <TableCell align="right">{numDead}</TableCell>
                  <TableCell align="right">{numTotal}</TableCell>
                  <TableCell align="right" style={{ maxWidth: 500 }}>
                    {comments}
                  </TableCell>
                  <TableCell align="right">{species}</TableCell>
                  <TableCell align="right">{age}</TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default LegacyDARTable;
