import { gql } from '@apollo/client';

export const ORDER_MUTATION = gql`
  mutation SubmitAnimalOrder(
    $receivingProtocolNumber: String!
    $receivingPI: String
    $orderingPI: String
    $orderBillingString: String
    $perDiemBillingString: String
    $receivingContactName: String!
    $receivingContactEmail: String!
    $receivingContactPhone: String!
    $receivingContactEmergencyPhone: String
    $addlEmails: String
    $dateRequired: String
    $species: String!
    $strain: String
    $age: String
    $numMales: Int!
    $numFemales: Int!
    $numNoSex: Int!
    $numCages: Int
    $vendor: String
    $room: String
    $animalDescription: String
    $immunodeficient: Boolean!
    $hazardExposure: Boolean!
    $biohazardAgent: String
    $receivingBuilding: String!
    $lengthOfStay: String
    $addlRequirements: String
  ) {
    submitAnimalOrder(
      receivingProtocolNumber: $receivingProtocolNumber
      receivingPI: $receivingPI
      orderingPI: $orderingPI
      orderBillingString: $orderBillingString
      perDiemBillingString: $perDiemBillingString
      receivingContactName: $receivingContactName
      receivingContactEmail: $receivingContactEmail
      receivingContactPhone: $receivingContactPhone
      receivingContactEmergencyPhone: $receivingContactEmergencyPhone
      addlEmails: $addlEmails
      dateRequired: $dateRequired
      species: $species
      strain: $strain
      age: $age
      numMales: $numMales
      numFemales: $numFemales
      numNoSex: $numNoSex
      numCages: $numCages
      vendor: $vendor
      room: $room
      animalDescription: $animalDescription
      immunodeficient: $immunodeficient
      hazardExposure: $hazardExposure
      biohazardAgent: $biohazardAgent
      receivingBuilding: $receivingBuilding
      addlRequirements: $addlRequirements
      lengthOfStay: $lengthOfStay
    ) {
      success
      message
    }
  }
`;

export const SUBMIT_IMPORT = gql`
  mutation SubmitImport(
    $receivingProtocolNumber: String!
    $receivingBuilding: String!
    $receivingRoom: String!
    $receivingPI: String
    $receivingContactName: String!
    $receivingContactEmail: String!
    $receivingContactPhone: String
    $receivingBillingString: String!
    $mtaStatus: String!
    $ackQuarantine: Boolean!
    $sendingInstitution: String!
    $sendingPI: String!
    $sendingContactName: String!
    $sendingContactEmail: String!
    $sendingTransferCoordinatorName: String
    $sendingTransferCoordinatorEmail: String
    $sendingVetName: String
    $sendingVetEmail: String
    $bsl: Int
    $biohazardAgent: String
    $immunodeficient: Boolean!
    $feed: String!
    $housing: String!
    $addlRequirements: String
    $species: String!
    $numMales: Int
    $numFemales: Int
    $numCages: Int
    $strain: String
    $animals: String
    $addlEmails: String
    $dateRequired: String
    $submitterEmail: String!
    $naive: Boolean
    $submitterPhone: String
  ) {
    submitImport(
      receivingProtocolNumber: $receivingProtocolNumber
      receivingBuilding: $receivingBuilding
      receivingRoom: $receivingRoom
      receivingContactName: $receivingContactName
      receivingPI: $receivingPI
      receivingContactEmail: $receivingContactEmail
      receivingContactPhone: $receivingContactPhone
      receivingBillingString: $receivingBillingString
      mtaStatus: $mtaStatus
      ackQuarantine: $ackQuarantine
      sendingInstitution: $sendingInstitution
      sendingPI: $sendingPI
      sendingContactName: $sendingContactName
      sendingContactEmail: $sendingContactEmail
      sendingTransferCoordinatorName: $sendingTransferCoordinatorName
      sendingTransferCoordinatorEmail: $sendingTransferCoordinatorEmail
      sendingVetName: $sendingVetName
      sendingVetEmail: $sendingVetEmail
      bsl: $bsl
      biohazardAgent: $biohazardAgent
      immunodeficient: $immunodeficient
      housing: $housing
      feed: $feed
      addlRequirements: $addlRequirements
      species: $species
      numMales: $numMales
      numFemales: $numFemales
      numCages: $numCages
      strain: $strain
      animals: $animals
      addlEmails: $addlEmails
      dateRequired: $dateRequired
      submitterEmail: $submitterEmail
      submitterPhone: $submitterPhone
      naive: $naive
    ) {
      success
      message
    }
  }
`;

export const GET_ALL_PROTOCOL_OUTLINES = gql`
  {
    allProtocolOutlines {
      _id
      protocolNumber
      label
      PIPVI
      buildings
      species
    }
  }
`;

export const CREATE_ERROR = gql`
  mutation CreateError($PVI: String, $action: String, $error: String, $data: String) {
    createError(PVI: $PVI, action: $action, error: $error, data: $data)
  }
`;