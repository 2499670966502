export const getStatusColor = (status) => {
  return status === 'Resolved'
    ? { backgroundColor: '#ADADAD', color: '#000000' }
    : status === 'Awaiting ACT Resolution'
    ? { backgroundColor: '#8DD4CE', color: '#000000' }
    : status === 'Awaiting Lab Resolution'
    ? { backgroundColor: '#FCCB51', color: '#000000' }
    : status === 'Overdue'
    ? { backgroundColor: '#C5050C', color: '#FFFFFF' }
    : { backgroundColor: '#000000', color: '#FFFFFF' };
};

// export const createSortHandler = (property) => (event) => {
//   onRequestSort(event, property);
// };

// const onRequestSort = (event, property) => {
//   const isAsc = orderBy === property && order === 'asc';
//   setOrder(isAsc ? 'desc' : 'asc');
//   setOrderBy(property);
// };

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

export const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};
